import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Area } from 'src/app/model/area';
import { Client } from 'src/app/model/client';
import { Contact } from 'src/app/model/contact';
import { CompresedGas } from 'src/app/model/compresedGas';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { Diet } from 'src/app/model/diet';
import { Equipment, EquipmentType } from 'src/app/model/equipment';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Quote, QuoteDTO, QuoteType } from 'src/app/model/quote';
import { QuoteService } from 'src/app/service/quote.service';
import { Room } from 'src/app/model/room';
import { StringUtils } from 'src/app/utils/stringUtils';
import { TestType } from 'src/app/model/test';
import { TestTypeService } from 'src/app/service/testType.service';
import { TranslateService } from '@ngx-translate/core';
import { Workcenter } from 'src/app/model/workCenter';
import { PaymentMode } from 'src/app/model/paymentMode';
import { DietTravelService } from 'src/app/service/dietTravel.service';
import { DietTravel, DietTravelFilter } from 'src/app/model/dietTravel';
import { ClientService } from 'src/app/service/client.service';
import { User } from 'src/app/model/user';
import { ContactService } from 'src/app/service/contactService';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { LoadingService } from 'src/app/service/loading.service';
import { EquipmentTypeService } from 'src/app/service/equipmentType.service';
import { PaymentMethodService } from 'src/app/service/paymentMethod.service';
import { QuoteDetailComponent } from '../quote-detail/quote-detail.component';
import { commercial } from 'src/app/model/commercial';
import { CommercialService } from 'src/app/service/commercial.service';
import { TimeZoneService } from 'src/app/service/timezone.service';
import { TimeZoneDTO } from 'src/app/model/timeZone';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { QuoteTypeService } from 'src/app/service/quoteType.service';
import { ProfileConsultancyType } from 'src/app/model/ProfileConsultancyType';
import { ConsultancyProfile } from 'src/app/model/consultancyProfile';
import { QuoteEditDialogAreaComponent } from './quote-edit-dialog-area/quote-edit-dialog-area.component';
import { QuoteEditDialogDietComponent } from './quote-edit-dialog-diet/quote-edit-dialog-diet.component';
import { QuoteEditDialogWorkcenterComponent } from './quote-edit-dialog-workcenter/quote-edit-dialog-workcenter.component';
import { QuoteEditEquipmentDialogComponent } from './quote-edit-equipment-dialog/quote-edit-equipment-dialog.component';
import { QuoteEditGasComponent } from './quote-edit-gas/quote-edit-gas.component';
import { QuoteEditRoomDialogComponent } from './quote-edit-room-dialog/quote-edit-room-dialog.component';
import { ConsultancyType } from 'src/app/model/ConsultancyType';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';
import { QuoteEditDialogProfileComponent } from './quote-edit-dialog-profile/quote-edit-dialog-profile.component';
import { TrainingEditDialogComponent } from './quote-edit-dialog-training/quote-edit-dialog-training.component';
import { ConsultancyCourse } from 'src/app/model/consultancyCourse';
import { CourseService } from 'src/app/service/course.service';
import { Course } from 'src/app/model/course';
import { AuditEditDialogComponent } from './quote-edit-dialog-audit/quote-edit-dialog-audit.component';
import { Audit } from '../../model/audit';
import { AuditType } from 'src/app/model/auditType';
import { AuditTypeService } from '../../service/audit-type.service';
import { QuoteDialogEquipmentThermal } from './quote-dialog-equipment-thermal/quote-dialog-equipment-thermal';
import { EquipmentThermal } from 'src/app/model/equipmentThermal';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { InterAreaResult } from 'src/app/model/interAreaResult';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { Calibration } from 'src/app/model/calibration';
import { CalibrationEditDialogComponent } from './quote-edit-calibration-dialog/quote-edit-calibration-dialog';
import { VariableType } from 'src/app/model/VariableType';
import { VariableTypeService } from 'src/app/service/variableType.service';
import { Unit } from 'src/app/model/unit';
import { UnitService } from 'src/app/service/unit.service';
import { CalibrationSite } from 'src/app/model/calibrationSite';
import { CalibratesRange } from 'src/app/model/calibratesRange';
import { CalibrationSiteService } from 'src/app/service/calibrationSite.service';
import { CalibratesRangeService } from 'src/app/service/calibratesRange.service';
import { MasiveCalibrationDialogComponent } from './quote-edit-dialog-masive-calibration/quote-edit-dialog-masive-calibration.component';
import { ProfileConsultancyTypeService } from '../../service/profileConsultancyType.service';
import { DuplicateDialogComponent } from '../shared/duplicate-dialog/duplicate-dialog.component';
import { ParticleCountType } from '../../model/particleCountType';
import { ParticleCountTypeService } from '../../service/particleCountType.service';
import { HoursResponse } from 'src/app/model/HoursResponse';
import { ConfirmationHoursDialogComponent } from './quote-edit-confirmationHours-dialog/quote-edit-confirmationHours-dialog';


@Component({
  selector: 'app-quote-edit',
  templateUrl: './quote-edit.component.html'
})

export class QuoteEditComponent implements OnInit, OnDestroy, AfterViewInit {
  [x: string]: any;

  @ViewChild('quoteDetailComponent') quoteDetailComponent: QuoteDetailComponent;
  @ViewChild("quoteTabs", { static: false }) quoteTabs: MatTabGroup;
  quote: Quote = new Quote();
  quoteDTO: QuoteDTO = new QuoteDTO();
  client: Client = new Client();
  matcher = new MyErrorStateMatcher();
  clients: Client[];
  clientsFiltered: Client[];
  commercials: commercial[];
  commercialsFiltered: commercial[];
  contacts: Contact[] = [];
  contact: Contact;
  contactsFiltered: Contact[];
  testTypes: TestType[] = [];
  quoteTypes: QuoteType[] = [];
  payments: PaymentMode[];
  paymentFiltered: PaymentMode[];
  dietTravels: DietTravel[];
  equipmentTypes: EquipmentType[];
  profileConsultancyType: ProfileConsultancyType[] = [];
  consultancyTypes: ConsultancyType[];
  courses: Course[];
  auditTypes: AuditType[];
  variablesTypes: VariableType[];
  unitTypes: Unit[];
  calibrationSites: CalibrationSite[];
  calibrateRanges: CalibratesRange[];;
  particleCountTypes: ParticleCountType[] = [];
  dietAmount = 0;
  percentajeGains = 0;

  quoteDTOList: Quote[] = [];
  results: InterAreaResult[] = [];

  isAirService = Constants.SERVICE_TYPE_AIR;
  isThermalService = Constants.SERVICE_TYPE_THERMAL;
  isCalibratesService = Constants.SERVICE_TYPE_CALIBRATES;
  isConsultancy = Constants.SERVICE_TYPE_CONSULTANCY;

  private destroy$ = new Subject<void>();

  workcenterTab = 0;
  loading$ = this.loader.loading$;
  dietTab = 0;
  versionTab = 0;
  timeZones: TimeZoneDTO[];
  valuesDiscountIncrease = ['Discount', 'Increase'];
  valueDiscountIncrease: string;
  showClosedPrice: boolean;

  selectedTabIndex: number;

  constructor(
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private dietTravelService: DietTravelService,
    private clientService: ClientService,
    private contactService: ContactService,
    private courseService: CourseService,
    private equipmentService: EquipmentTypeService,
    private quoteService: QuoteService,
    private paymentMethodService: PaymentMethodService,
    public loader: LoadingService,
    private testTypeService: TestTypeService,
    private translate: TranslateService,
    private router: Router,
    private commercialService: CommercialService,
    private timeZoneService: TimeZoneService,
    private quoteTypeService: QuoteTypeService,
    private profileConsultancyTypeService: ProfileConsultancyTypeService,
    private consultancyTypeService: ConsultancyTypeService,
    private auditTypeService: AuditTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private unitService: UnitService,
    private variableTypeService: VariableTypeService,
    private calibrationSiteService: CalibrationSiteService,
    private calibrationRangeService: CalibratesRangeService,
    private particleCountTypeService: ParticleCountTypeService
  ) {
  }

  ngOnInit(): void {
    this.loader.show();

    this.route.queryParams.subscribe(params => {
      // Recuperamos el id que viene en la url
      let id = params[Constants.QUOTE_ID] as number;
      // Recuperamos tipos de equipos
      this.equipmentService.findAll().subscribe((res: EquipmentType[]) => {
        this.equipmentTypes = res;
      });
      this.timeZoneService.findAllCommercial().subscribe((res: TimeZoneDTO[]) => {
        this.timeZones = res;
      });

      this.quoteTypeService.findAll().subscribe((res: QuoteType[]) => {
        this.quoteTypes = res;
      });
      // calibrations   unitTypes
      this.calibrationSiteService.findAll().subscribe((res: EquipmentType[]) => {
        this.calibrationSites = res;
      });
      this.variableTypeService.findAll().subscribe((res: VariableType[]) => {
        this.variablesTypes = res;
      });
      this.calibrationRangeService.findAll().subscribe((res: CalibratesRange[]) => {
        this.calibrateRanges = res;
      });
      this.unitService.findAll().subscribe((res: Unit[]) => {
        this.unitTypes = res;
      });
      this.particleCountTypeService.findAll().subscribe((res: ParticleCountType[]) => {
        this.particleCountTypes = res;
      });
      // Recuperamos los clientes
      this.clientService.findAll().subscribe((res: Client[]) => {
        this.clients = res;
        this.clientsFiltered = this.clients.slice();

        this.contactService.findAllClientContact().subscribe((res: Contact[]) => {
          this.contacts = res;

          this.contactsFiltered = this.contacts?.slice();
          this.contactsFiltered?.sort((a, b) => a.fullName < b.fullName ? -1 : +(a.fullName > b.fullName));
          if (id < 1) {
            this.quoteDTO = new QuoteDTO();
            this.quoteDTO.translateStatus = this.getStatus(1);
            this.quoteDTO.idStatus = 1;
            this.quoteDTO.active = true;
            this.quoteDTO.recalculate = true;

            // Obtener el usuario logado
            const user = JSON.parse(localStorage.getItem('profile')) as User
            this.quoteDTO.idUser = user.id;
            this.quoteDTO.userfullName = user.fullName;
            this.contact = new Contact();
            this.quoteDTO.idTimeZone = 1;
          } else {
            this.quoteService.findOne(id).subscribe((q: any) => {
              this.quoteDTO = q;
              this.quoteDTO.workcenters.forEach(wc => {
                wc.areas.sort(a => a.id);
                wc.equipmentThermal?.sort(a => a.id);
                wc.areas.forEach(a => {
                  a.rooms.sort((a, b) => a.roomNumber < b.roomNumber ? -1 : +(a.roomNumber > b.roomNumber));
                  a.equipments.sort((a, b) => a.inventoryNumber < b.inventoryNumber ? -1 : +(a.inventoryNumber > b.inventoryNumber));
                  a.compressedGases.sort(a => a.id);
                });
              });
              this.onShowVersion();
              if (this.quoteDTO.idClient != null) {
                // Recuperamos el cliente que tiene la oferta del listado    
                this.client = this.clients.find(c => c.id === this.quoteDTO.idClient);
              }
              if (this.quoteDTO.idClientContact != null) {
                this.contact = this.contacts.find(c => c.id === this.quoteDTO.idClientContact);
              }
              this.quoteDTO.translateStatus = this.getStatus(this.quoteDTO.idStatus);
            });
            this.testTypeEquipmentConfigService.totalCostTestPerArea(id).subscribe((res: InterAreaResult[]) => {
              this.results = res;
              this.percentajeGains = this.getPercentajeGains(id);
            });
          }

        });
        this.loader.hide();
      });

    });

    // Recuperamos los tipos de Test
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });

    this.courseService.findAll().subscribe((res: Course[]) => {
      this.courses = res;
    });

    let filter = new DietTravelFilter();
    // Recuperamos los tipos de dieta
    this.dietTravelService.findAll(filter).subscribe((res: DietTravel[]) => {
      this.dietTravels = res;
    });

    // Recuperar los comerciales
    this.commercialService.findAllCommercial().subscribe((res: commercial[]) => {
      this.commercials = res;
    });

    this.paymentMethodService.findAll().subscribe((res: PaymentMode[]) => {
      this.payments = res;
    });

    if (this.quoteDTO.idTimeZone == null) {
      this.quoteDTO.idTimeZone = 1;
    }

    this.profileConsultancyTypeService.findAll().subscribe((res: ProfileConsultancyType[]) => {
      this.profileConsultancyType = res;
    });

    this.auditTypeService.findAll().subscribe((res: AuditType[]) => {
      this.auditTypes = res;
    });

    this.variableTypeService.findAll().subscribe((res: VariableType[]) => {
      this.variablesTypes = res;
    });

    this.unitService.findAll().subscribe((res: Unit[]) => {
      this.unitTypes = res;
    })
    //Recuperamos los tipos de consultoria
    this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => this.consultancyTypes = types);
  }


  openDialogWorkcenter(): void {
    const dialogRef = this.dialog.open(QuoteEditDialogWorkcenterComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        workcenter: new Workcenter(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Workcenter) => {
      if (result != null) {
        this.quoteDTO.workcenters.push(result);
      }
    });
  }

  openDialogEditDiet(indexWC: number, indexDiet: number): void {

    const eq = this.quoteDTO.workcenters[indexWC].dietDTOs[indexDiet];
    let dietTravel: DietTravel = new DietTravel();
    dietTravel.id = eq.idDietTravel;

    const dialogRef = this.dialog.open(QuoteEditDialogDietComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        diet: _.cloneDeep(eq),
        isEdit: true,
        dietTravel: dietTravel
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Diet) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].dietDTOs[indexDiet] = result;
      }
    });
  }

  // EquipmentThermal

  openDialogEquipmentThermal(indexWC: number) {
    const dialogRef = this.dialog.open(QuoteDialogEquipmentThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentThermal: new EquipmentThermal(),
        isEdit: false,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_THERMAL
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentThermal) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[indexWC].equipmentThermal == null) {
          this.quoteDTO.workcenters[indexWC].equipmentThermal = [];
        }
        this.quoteDTO.workcenters[indexWC].equipmentThermal.push(result);
      }
    });
  }

  editEquipmentThermal(indexWC: number, indexEq: number): void {
    const eq = this.quoteDTO.workcenters[indexWC].equipmentThermal[indexEq];

    const dialogRef = this.dialog.open(QuoteDialogEquipmentThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentThermal: _.cloneDeep(eq),
        isEdit: true,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_THERMAL
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentThermal) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].equipmentThermal[indexEq] = result;
      }
    });
  }

  deleteEquipmentThermal(indexWC: number, indexEq: number): void {
    const message = this.translate.instant('quoteEdit.dialog.equipment.thermal.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexEq > -1) {
          this.quoteDTO.workcenters[indexWC].equipmentThermal.splice(indexEq, 1);
        }
      }
    });
  }

  openDialogCalibration(indexWC: number) {
    const dialogRef = this.dialog.open(CalibrationEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        calibration: new Calibration(),
        isEdit: false,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Calibration) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[indexWC].calibrations == null) {
          this.quoteDTO.workcenters[indexWC].calibrations = [];
        }
        this.quoteDTO.workcenters[indexWC].calibrations.push(result);
      }
    });
  }

  openDialogUploadMasiveCalibration(indexWC: number) {
    const dialogRef = this.dialog.open(MasiveCalibrationDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Calibration[]) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[indexWC].calibrations == null) {
          this.quoteDTO.workcenters[indexWC].calibrations = [];
        }

        this.quoteDTO.workcenters[indexWC].calibrations = [...this.quoteDTO.workcenters[indexWC].calibrations, ...result];
      }
    });
  }

  openDialogEditCalibration(indexWC: number, indexCal: number) {
    const calibration = this.quoteDTO.workcenters[indexWC].calibrations[indexCal];
    const dialogRef = this.dialog.open(CalibrationEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        calibration: _.cloneDeep(calibration),
        isEdit: true,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Calibration) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[indexWC].calibrations == null) {
          this.quoteDTO.workcenters[indexWC].calibrations = [];
        }
        this.quoteDTO.workcenters[indexWC].calibrations[indexCal] = result;
      }
    });
  }

  deleteCalibration(indexWC: number, indexCal: number): void {
    const message = this.translate.instant('quoteEdit.dialog.equipment.calibration.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true && indexCal > -1) {
        this.quoteDTO.workcenters[indexWC].calibrations.splice(indexCal, 1);
      }
    });
  }

  cloneCalibration(indexWC: number, indexCal: number): void {
    const dialogRef = this.dialog.open(DuplicateDialogComponent, {
      minWidth: '30%',
      maxHeight: '30',
    });
    const calibration = this.quoteDTO.workcenters[indexWC].calibrations[indexCal];

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response != null && response > 0) {
        for (let i = 0; i < response; i++) {
          this.quoteDTO.workcenters[indexWC].calibrations.push(this.cleanCalibration(calibration));
        }
      }
    });
  }

  openDialogTraining(indexWC: number): void {

    const dialogRef = this.dialog.open(TrainingEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        course: new ConsultancyCourse(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ConsultancyCourse) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].courses.push(result);
      }
    });
  }

  openDialogEditTraining(indexWC: number, indexCourse: number): void {

    const eq = this.quoteDTO.workcenters[indexWC].courses[indexCourse];

    const dialogRef = this.dialog.open(TrainingEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        course: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ConsultancyCourse) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].courses[indexCourse] = result;
      }
    });
  }

  openDialogEditWorkcenter(idx: number): void {
    const eq = this.quoteDTO.workcenters[idx];
    const dialogRef = this.dialog.open(QuoteEditDialogWorkcenterComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        workcenter: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Workcenter) => {
      if (result != null) {
        this.quoteDTO.workcenters[idx] = result;
      }
    });
  }

  onWorkcenterTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.workcenterTab = tabChangeEvent.index;

  }
  onDietTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.dietTab = tabChangeEvent.index;

  }

  onVersionamientoTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.versionTab = tabChangeEvent.index;

  }

  deleteWorkDiet(indexWC: number, indexDiet: number) {
    const message = this.translate.instant('quoteEdit.dialog.diet.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexDiet > -1) {
          this.quoteDTO.workcenters[indexWC].dietDTOs.splice(indexDiet, 1);
        }
      }
    });
  }

  deleteTraining(indexWC: number, indexCourse: number) {
    const message = this.translate.instant('quoteEdit.dialog.training.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexCourse > -1) {
          this.quoteDTO.workcenters[indexWC].courses.splice(indexCourse, 1);
        }
      }
    });
  }

  deleteArea(indexWC: number, index: number): void {
    const message = this.translate.instant('quoteEdit.dialog.area.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.quoteDTO.workcenters[indexWC].areas.splice(index, 1);
        }
      }
    });
  }

  deleteEquipment(area: Area, indexEquipment: number) {
    const message = this.translate.instant('quoteEdit.dialog.room.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexEquipment > -1) {
          area.equipments.splice(indexEquipment, 1);
        }
      }
    });
  }

  deleteRoom(area: Area, roomIndex: number): void {
    const message = this.translate.instant('quoteEdit.dialog.room.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (roomIndex > -1) {
          area.rooms.splice(roomIndex, 1);
        }
      }
    });
  }

  deleteGas(area: Area, gasIndex: number): void {
    const message = this.translate.instant('quoteEdit.dialog.gas.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (gasIndex > -1) {
          area.compressedGases.splice(gasIndex, 1);
        }
      }
    });
  }

  deleteWorkcenter(index: number): void {
    const message = this.translate.instant('quoteEdit.dialog.workcenter.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.quoteDTO.workcenters.splice(index, 1);
        }
      }
    });
  }

  // Añadir area
  openDialogArea(indexWC: number): void {
    const dialogRef = this.dialog.open(QuoteEditDialogAreaComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        area: new Area(),
        isEdit: false,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[indexWC].areas == null) {
          this.quoteDTO.workcenters[indexWC].areas = [];
        }
        this.quoteDTO.workcenters[indexWC].areas.push(result);
      }
    });
  }

  // Añadir dietas
  openDialogDiet(workcenter: any): void {
    const dialogRef = this.dialog.open(QuoteEditDialogDietComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        diet: new Diet(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Diet) => {
      if (result != null) {
        if (workcenter.dietDTOs == null) {
          workcenter.dietDTOs = [];
        }
        workcenter.dietDTOs.push(result);
      }
    });
  }

  // Editar area
  openDialogEditArea(indexWC: number, idx: number): void {
    const area = this.quoteDTO.workcenters[indexWC].areas[idx];

    const dialogRef = this.dialog.open(QuoteEditDialogAreaComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        area: _.cloneDeep(area),
        isEdit: true,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].areas[idx] = result;
      }
    });
  }

  onAreaTabChanged(tabChangeEvent: MatTabChangeEvent): void {

  }

  getTranslationFromQuoteType(quoteTypeId: number): string {
    switch (quoteTypeId) {
      case 1:
        return this.translate.instant('quoteEdit.areas.title');
      case 4:
        return this.translate.instant('quoteEdit.area.title');
      default:
        return "";
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {

  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  openDialogRoom(workCenterIndex: number, areaIndex: number): void {

    const dialogRef = this.dialog.open(QuoteEditRoomDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        room: new Room(),
        isEdit: false,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Room) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].rooms == null) {
          this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].rooms = [];
        }
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].rooms.push(result);
      }
    });
  }

  openDialogEquipment(workCenterIndex: number, areaIndex: number): void {
    const dialogRef = this.dialog.open(QuoteEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new Equipment(),
        isEdit: false,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equipments == null) {
          this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equipments = [];
        }
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equipments.push(result);
      }
    });
  }

  /**
   * Method allows to edit 'equipment'
   * @param workCenterIndex workcenter index
   * @param areaIndex area index
   * @param eqIndex equipment index
   */
  openDialogEditEquipment(workCenterIndex: number, areaIndex: number, eqIndex: number): void {
    let equipment = this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equipments[eqIndex];
    const dialogRef = this.dialog.open(QuoteEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(equipment),
        isEdit: true,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equipments[eqIndex] = result;
      }
    });
  }


  openDialogEditRoom(workCenterIndex: number, areaIndex: number, roomIndex: number): void {
    let room = this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].rooms[roomIndex];
    const dialogRef = this.dialog.open(QuoteEditRoomDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        room: _.cloneDeep(room),
        isEdit: true,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Room) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].rooms[roomIndex] = result;
      }
    });
  }

  showEquipment(eq: Equipment): string {
    const arr: string[] = [];

    if (StringUtils.isNotEmpty(eq.maker)) {
      arr.push(`(${eq.maker})`);
    }
    if (StringUtils.isNotEmpty(eq.model)) {
      arr.push(`(${eq.model})`);
    }
    if (StringUtils.isNotEmpty(eq.serialNumber)) {
      arr.push(`(${eq.serialNumber})`);
    }

    return arr.join(' ');
  }

  updateProfileList(index: number, item: any): number {
    return item.id;
  }

  getEquipmentType(eq: Equipment): string {
    const eqType = this.equipmentTypes.find(e => e.id === eq.idType);
    return eqType?.translate;
  }

  openDialogGas(workCenterIndex: number, areaIndex: number): void {
    const dialogRef = this.dialog.open(QuoteEditGasComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        gas: new CompresedGas(),
        isEdit: false,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompresedGas) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].compressedGases == null) {
          this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].compressedGases = [];
        }
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].compressedGases.push(result);
      }
    });
  }

  openDialogThermalEquioment(workCenterIndex: number, areaIndex: number): void {
    const dialogRef = this.dialog.open(QuoteDialogEquipmentThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentThermal: new EquipmentThermal(),
        isEdit: false,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentThermal) => {
      if (result != null) {
        if (this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equpmentThermal == null) {
          this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equpmentThermal = [];
        }
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equpmentThermal.push(result);
      }
    });
  }

  openDialogEditThermalEquioment(workCenterIndex: number, areaIndex: number, thermalEquipmentIndex: number): void {
    let equipmentThermal = this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equpmentThermal[thermalEquipmentIndex];
    const dialogRef = this.dialog.open(QuoteDialogEquipmentThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentThermal: _.cloneDeep(equipmentThermal),
        isEdit: true,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentThermal) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].equpmentThermal[thermalEquipmentIndex] = result;
      }
    });
  }

  deleteThermalEquioments(area: Area, thermalEquipmentIndex: number): void {
    const message = this.translate.instant('quoteEdit.dialog.equipment.thermal.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (thermalEquipmentIndex > -1) {
          area.equpmentThermal.splice(thermalEquipmentIndex, 1);
        }
      }
    });
  }

  addConsultancyProfile(workCenterIndex: number, areaIndex: number): void {
    const dialogRef = this.dialog.open(QuoteEditDialogProfileComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        profile: new ConsultancyProfile(),
        isEdit: false,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ConsultancyProfile) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].consultancyProfiles.push(result);
      }
    });
  }

  openDialogEditConsultancyProfile(workCenterIndex: number, areaIndex: number, profileIndex: number): void {
    let profile = this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].consultancyProfiles[profileIndex];
    const dialogRef = this.dialog.open(QuoteEditDialogProfileComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        profile: _.cloneDeep(profile),
        isEdit: true,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ConsultancyProfile) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].consultancyProfiles[profileIndex] = result;
      }
    });
  }

  deleteConsultancyProfile(workCenterIndex: number, areaIndex: number, profileIndex: number): void {
    const message = this.translate.instant('quoteEdit.dialog.profile.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (profileIndex > -1) {
          this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].consultancyProfiles.splice(profileIndex, 1);
        }
      }
    });
  }

  /**
   * Method allows to edit 'gas'
   * @param equipment equipment edit
   */
  openDialogEditGas(workCenterIndex: number, areaIndex: number, gasIndex: number): void {
    let gas = this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].compressedGases[gasIndex];
    const dialogRef = this.dialog.open(QuoteEditGasComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        gas: _.cloneDeep(gas),
        isEdit: true,
        showAir: this.quoteDTO.idType === Constants.QUOTE_TYPE_AIR || this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY
          || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED,
        showQualis: this.quoteDTO.idType === Constants.QUOTE_TYPE_CONSULTANCY || this.quoteDTO.idType === Constants.QUOTE_TYPE_MIXED
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompresedGas) => {
      if (result != null) {
        this.quoteDTO.workcenters[workCenterIndex].areas[areaIndex].compressedGases[gasIndex] = result;
      }
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('/quotes');
  }

  download(): void {
    this.loader.show();
    this.quoteService.getPdf(this.quoteDTO.id).subscribe(
      (data: Blob) => {
        let file = new Blob([data], { type: 'application/pdf' })
        let fileURL = URL.createObjectURL(file);

        // if you want to open PDF in new tab
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = this.quoteDTO.quoteNumber;
        document.body.appendChild(a);
        a.click();
        this.loader.hide();
      },
      (error) => {
        this.loader.hide();
        console.log('getPDF error: ', error);
      }
    );
  }

  saveQuote(): void {
    let errs: string[] = [];
    const isNew = this.quoteDTO.id == null;
    errs = this.checkErrors();
    if (StringUtils.isEmpty(this.quoteDTO.valueDiscountIncrease)) {
      if (this.quoteDTO.discountIncrease < 0) {
        errs.push(this.translate.instant('quoteEdit.general.discountIncrease.error') as string);
      }
    }
    if (errs.length === 0) {
      this.loader.show();
      this.quoteService.save(this.quoteDTO).pipe(takeUntil(this.destroy$)).subscribe((quote: QuoteDTO) => {
        this.quoteDTO = quote;
        if (quote.id != null) {
          this.quoteService.saveQuoteTotalAmount(quote.id).pipe(takeUntil(this.destroy$)).subscribe((quote: QuoteDTO) => {
            this.quoteDTO = quote;
          });
        }
        if (isNew) {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);
        } else {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
          window.location.reload();
        }
        void this.router.navigateByUrl(`/quote?id=${quote.id}`);
       this.loader.hide();
      });
    } else {
      this.snackBarService.sendError(errs.join('\n'));
      this.loader.hide();
    }
  }

  getTestName(idType: number): string {
    let testName = '';
    this.testTypes.forEach(element => {
      if (element.id === idType) {
        testName = 'testType.'.concat(element.translate);
      }
    });

    return testName;
  }

  getQualificationName(idType: number): string {
    let qualiName = '';
    this.consultancyTypes.forEach(element => {
      if (element.id === idType) {
        qualiName = 'consultancy.type.'.concat(element.translate);
      }
    });

    return qualiName;
  }

  showDietName(idType: number): string {
    let dietName = '';
    this.dietTravels.forEach(element => {
      if (element.id === idType) {
        dietName = element.traduction;
      }
    });
    return dietName;
  }

  showCourseName(idType: number): string {
    let courseName = '';
    this.courses.forEach(element => {
      if (element.id === idType) {
        courseName = element.code.concat(" - ").concat(element.name);
      }
    });
    return courseName;
  }

  showConsultancyName(idType: number): string {
    let name = '';
    this.consultancyTypes.forEach(element => {
      if (element.id === idType) {
        name = element.translate;
      }
    });
    return 'consultancy.type.'.concat(name);
  }

  showProfileName(idType: number): string {
    let name = '';
    this.profileConsultancyType.forEach(element => {
      if (element.id === idType) {
        name = element.translate;
      }
    });
    return 'profile.consultancy.type.'.concat(name);
  }

  onClientChange(event: MatSelectChange): void {
    let id: number = event.value;
    this.client = this.clients.find(c => c.id === id);
    if (ArrayUtils.isEmpty(this.quoteDTO.workcenters)) {
      const wc = new Workcenter();
      wc.address = this.client.address;
      wc.name = this.client.name;
      this.quoteDTO.workcenters.push(wc);
    }
  }

  onQuoteTypeChange(event: MatSelectChange): void {
    let idType: number = event.value;
    if (this.quoteDTO.id > 0) {
      const message = this.translate.instant('quoteEdit.dialog.type.form.confirmChange') as string;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response === true) {
          if (idType === Constants.QUOTE_TYPE_THERMAL) {
            this.cleanAirServices();
            this.cleanCalibrationServices();
          } else if (idType === Constants.QUOTE_TYPE_CONSULTANCY) {
          } else if (Constants.SERVICE_TYPE_CALIBRATES) {

          }
        } else {
          this.quoteDTO.idType = null;
        }
      });
    }
  }
  onDiscountIncreaseChange(discountIncrease: MatSelectChange): void {
    this.quoteDTO.discountIncrease = 0;
  }

  ontContactChange(contact: MatSelectChange): void {
    this.contact = this.contacts.find(c => c.id === this.quoteDTO.idClientContact);
  }

  showGasName(gas: CompresedGas): string {
    return gas?.name;
  }

  advance(): void {
    if (this.percentajeGains < 15) {
      const message = this.translate.instant('quoteEdit.dialog.quote.form.confirmUnder15') as string;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response === true) {
          this.quoteDTO.idStatus = 2;
          this.saveQuote();
        }
      });
    } else {
      this.quoteDTO.idStatus = 2;
      this.saveQuote();
    }

  }

  send(): void {
    this.quoteDTO.idStatus = 3;
    this.saveQuote();
  }

  retreat(): void {
    this.quoteDTO.idStatus = this.quoteDTO.idStatus - 1;
    this.saveQuote();
  }

  showAdvance(): boolean {
    let sw: boolean;
    if (this.quoteDTO.idStatus === 6) {
      sw = false;
    } else {
      sw = this.quoteDTO.idStatus <= 1;
    }
    return sw;
  }

  getStatus(idStatus: number): string {
    if (idStatus == 1) {
      return 'En preparación'
    } else if (idStatus == 2) {
      return 'Firmada'
    } else if (idStatus == 3) {
      return 'Enviada'
    } else if (idStatus == 4) {
      return 'Ganada'
    } else if (idStatus == 5) {
      return 'Perdida'
    } else if (idStatus == 6) {
      return 'Archivada'
    } else {
      return 'Nueva Versión'
    }
  }

  showSend(): boolean {
    return this.quoteDTO.idStatus == 2;
  }

  showRetreat(): boolean {
    return this.quoteDTO.idStatus >= 2 && this.quoteDTO.idStatus < 4;
  }

  showIncremet(): boolean {
    return this.quoteDTO.id != null;
  }

  onConsultorytypeChance(event: MatSelectChange): void {
    let type: number = event.value;
    this.showClosedPrice = (type === Constants.PROFILE_TYPE_CLOSED_PRICE);
  }

  private reloadPage(idQuote = this.quoteDTO.id) {
    if (idQuote == null) {
      idQuote = this.quoteDTO.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`/quote?id=${idQuote}`);
  }

  private checkErrors(): string[] {
    let errs: string[] = [];

    if (StringUtils.isEmpty(this.quoteDTO.scope)) {
      errs.push(this.translate.instant('protocolEdit.scope.error') as string);
    }
    if (StringUtils.isEmpty(this.quoteDTO.descriptionService)) {
      errs.push(this.translate.instant('protocolEdit.descriptionService.error') as string);
    }
    if (StringUtils.isEmpty(this.quoteDTO.quoteNumber)) {
      errs.push(this.translate.instant('protocolEdit.quoteNumber.error') as string);
    }
    if (this.quoteDTO.idClientContact == null) {
      errs.push(this.translate.instant('protocolEdit.idClientContact.error') as string);
    }
    if (this.quoteDTO.idTimeZone == null) {
      errs.push(this.translate.instant('quoteEdit.general.idTimeZone.error') as string);
    }

    if (this.quoteDTO.idType == null) {
      errs.push(this.translate.instant('quoteEdit.general.idType.error') as string);
    }
    this.quoteDTO.workcenters.forEach(wc => {
      if (wc.address == null || wc.name == null) {
        errs.push(this.translate.instant('quoteEdit.general.workcenterInfo.error') as string);
      }
      wc.calibrations.forEach(cal => {
        if (cal.serie === null && cal.indicative === null) {
          errs.push(this.translate.instant('quoteEdit.general.calibrationInfo.error') as string);
        }
      });
    });

    return errs;
  }

  saveQuoteVersion(): void {
    let errs: string[] = [];

    errs = this.checkErrors();

    if (StringUtils.isEmpty(this.quoteDTO.valueDiscountIncrease)) {
      if (this.quoteDTO.discountIncrease < 0) {
        errs.push(this.translate.instant('quoteEdit.general.discountIncrease.error') as string);
      }
    }

    if (errs.length === 0) {
      this.loader.show();
      this.quoteService.saveVersion(this.quoteDTO.id).pipe(takeUntil(this.destroy$)).subscribe((quote: QuoteDTO) => {
        this.quoteDTO = quote;
        if (quote) {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

          void this.router.navigateByUrl(`/quote?id=${quote.id}`);

          this.ngOnInit();
          this.quoteDetailComponent?.ngOnInit();

          this.loader.hide();
        } else {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);
          this.loader.hide();
        }
      });

    } else {
      this.snackBarService.sendError(errs.join('\n'));
      this.loader.hide();
    }
  }

  downloadVersion(dto: QuoteDTO): void {
    this.loader.show();
    this.quoteService.getPdf(dto.id).subscribe(
      (data: Blob) => {
        let file = new Blob([data], { type: 'application/pdf' })
        let fileURL = URL.createObjectURL(file);

        // if you want to open PDF in new tab
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = dto.quoteNumber + "-" + dto.numVersion;
        document.body.appendChild(a);
        a.click();
        this.loader.hide();
      },
      (error) => {
        this.loader.hide();
        console.log('getPDF error: ', error);
      }
    );
  }

  onShowVersion() {
    this.loader.show();
    if (this.quoteDTO.idVersionControl) {
      this.quoteService.findAllByIdVersionControl(this.quoteDTO.idVersionControl).subscribe((res: Quote[]) => {
        this.quoteDTOList = res;
        this.loader.hide();
      });
    } else {
      this.loader.hide();
    }
  }

  showWonAndLost(): boolean {
    return this.quoteDTO.idStatus === 3;
  }

  showSave(): boolean {
    let sw = true;
    if (this.quoteDTO.idStatus !== 1) {
      sw = false;
    }
    return sw;
  }

  sendWon(): void {
    this.quoteDTO.idStatus = this.quoteDTO.idStatus = 4;
    this.saveQuoteVersionEnd();
  }

  sendLost(): void {
    this.quoteDTO.idStatus = this.quoteDTO.idStatus = 5;
    this.saveQuoteVersionEnd();
  }

  saveQuoteVersionEnd(): void {
    let errs: string[] = [];

    errs = this.checkErrors();

    if (StringUtils.isEmpty(this.quoteDTO.valueDiscountIncrease)) {
      if (this.quoteDTO.discountIncrease < 0) {
        errs.push(this.translate.instant('quoteEdit.general.discountIncrease.error') as string);
      }
    }

    if (errs.length === 0) {
      this.loader.show();
      this.quoteService.saveVersionEnd(this.quoteDTO).pipe(takeUntil(this.destroy$)).subscribe((quote: QuoteDTO) => {
        this.quoteDTO = quote;
        if (quote) {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

          void this.router.navigateByUrl(`/quote?id=${quote.id}`);

          this.ngOnInit();
          this.quoteDetailComponent?.ngOnInit();

          this.loader.hide();
        } else {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);
          this.loader.hide();
        }
      });

    } else {
      this.snackBarService.sendError(errs.join('\n'));
      this.loader.hide();
    }
  }

  editVersion(id: number) {
    void this.router.navigateByUrl(`/quote?id=${id}`);
    this.selectedTabIndex = this.selectedTabIndex - 1;
    this.ngOnInit();
  }

  openDialogEditAudit(indexWC: number, indexAudit: number): void {
    let audit = this.quoteDTO.workcenters[indexWC].auditDTOs[indexAudit];
    const dialogRef = this.dialog.open(AuditEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        audit: _.cloneDeep(audit),
        isEdit: true,

      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      if (result != null) {
        this.quoteDTO.workcenters[indexWC].auditDTOs[indexAudit] = result;
      }
    });
  }

  openDialogAudit(workcenter: Workcenter) {
    const dialogRef = this.dialog.open(AuditEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        audit: new Audit(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Audit) => {
      if (result != null) {
        if (workcenter.auditDTOs == null) {
          workcenter.auditDTOs = [];
        }
        workcenter.auditDTOs.push(result);
      }
    });
  }

  showAuditName(idType: number): string {
    let name = '';
    this.quoteDTO.workcenters;
    this.auditTypes.forEach(e => {
      if (e.id === idType) {
        name = e.translate;
      }
    });
    return 'quoteEdit.general.audit.'.concat(name);
  }

  deleteWorkAudit(indexWC: number, indexAudit: number) {
    if (indexAudit != null) {
      this.quoteDTO.workcenters[indexWC].auditDTOs.splice(indexAudit, 1);
    }
  }

  showCalibrationName(calibration: Calibration): string {

    const equipmentTypeId = this.equipmentTypes.find(eq => eq.id === calibration.equipmentType)?.id;
    const equipment = this.equipmentTypes.find(eq => eq.id === calibration.equipmentType).translate;
    let translatedPhrase;

    const maker = calibration.maker ? calibration.maker : '';
    const model = calibration.model ? calibration.model : '';
    const ubication = calibration.location ? calibration.location : '';
    const indicativo = calibration.indicative ? calibration.indicative : '';
    const serie = calibration.serie ? calibration.serie : '';
    const equipmentType = this.equipmentTypes.find(eq => eq.id === calibration.equipmentType)?.translate;

    if (equipmentTypeId !== Constants.EQUIPMENT_TYPE_PARTICLE_COUNTER) {

      const variableType = this.variablesTypes.find(va => va.id === calibration.variableType)?.translate;
      const unitType = this.unitTypes.find(un => un.id === calibration.unitType)?.translate;
      const site = this.calibrationSites.find(cs => cs.id === calibration.calibrationSite)?.translate;
      const range = this.calibrateRanges.find(cr => cr.id === calibration.calibrationRange)?.rangeDescription;

      translatedPhrase = this.translate.instant('quoteEdit.general.calibrations.prase', {
        equipmentType: this.translate.instant(`equipmentType.${equipmentType}`),
        variableType: this.translate.instant(`variableType.${variableType}`),
        calibrateRange: this.translate.instant(`calibrateRange.${range}`),
        unitType: this.translate.instant(`unitType.${unitType}`),
        calibrationSite: this.translate.instant(`calibrationSite.${site}`),
        ubication
      });
    } else {
      const particleCountType = this.particleCountTypes.find(pc => pc.id === calibration.particleCounterType)?.name;
      translatedPhrase = this.translate.instant('quoteEdit.general.calibrations.particleType.prase', {
        equipmentType: this.translate.instant(`equipmentType.${equipmentType}`),
        particleType: particleCountType
      });
    }
    
    let finalPhrase = translatedPhrase + '.\n';
    
    if(calibration.serie == null && calibration.indicative == null) {
      return this.translate.instant('quoteEdit.general.calibrations.noSerieNoId');
    }
    if (calibration.serie == null) {
      return finalPhrase += this.translate.instant('quoteEdit.general.calibrations.prase4', { maker, indicativo, model, ubication });
    }
    else if (calibration.indicative == null) {
      return finalPhrase += this.translate.instant('quoteEdit.general.calibrations.prase3', { maker, model, serie, ubication });
    }
    else {
      return finalPhrase += this.translate.instant('quoteEdit.general.calibrations.prase2', { maker, serie, model, indicativo, ubication });
    }
  }


  private cleanAirServices(): void {
    this.quoteDTO.workcenters.forEach(workcenter => {
      workcenter.areas.forEach(area => {
        area.equipments = [];
        area.rooms = [];
        area.compressedGases = [];
      });
    });
  }

  private cleanCalibrationServices(): void {
    this.quoteDTO.workcenters.forEach(workcenter => {
      workcenter.auditDTOs = [];
      workcenter.courses = [];
      workcenter.areas.forEach(area => {
        area.consultancyProfiles = [];
        area.consultoscoperyScope = null;
      })
    });
  }

  private getPercentajeGains(id: number): number {
    const total = this.quoteDTO.totalAmount - this.getTotalAmountDietGroupByQuote(id);
    let price = (total - this.getTotalProductionPrice()) * 100 / total;
    return NumberUtils.round(price, 2);
  }

  private getTotalAmountDietGroupByQuote(id: number): number {
    this.dietTravelService.totalAmountDietGroupByQuote(id).subscribe(res => {
      this.dietAmount = res.sumDiets;
    });
    return this.dietAmount;
  }

  private getTotalProductionPrice(): number {
    let price = 0;
    this.results.forEach(result => {
      result.areas.forEach(area => {
        price += area.costProduction;
      })
    });
    return price;
  }

  private cleanCalibration(calibration: Calibration): Calibration {
    const newCal = _.cloneDeep(calibration) as Calibration;
    newCal.id = null;
    newCal.serie = null;
    newCal.indicative = null;

    return newCal;
  }

  confirmationHours(): void {
    const message = this.translate.instant('quoteEdit.dialog.confirmation.hours') as string;
    const dialogRef = this.dialog.open(ConfirmationHoursDialogComponent, { data: { message } });
  
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((response: HoursResponse) => {
      if (response && response.hours > 0) {
        this.quoteDTO.workcenters.forEach((wk)=>{
           const diet= new Diet();
           diet.idDietTravel= Constants.DIET_TYPE_HOURS;
           diet.amount=response.hours;
           wk.dietDTOs.push(diet);
        });
      } 
      this.saveQuote();
    });
  }
}

