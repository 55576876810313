<form>
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'quoteList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'quoteList.filter.quoteNumber.label' | translate }}"
                            name="quoteNumber" [(ngModel)]="filter.quoteNumber">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'quoteList.filter.client.label' | translate }}</mat-label>
                        <mat-select #clientSelect name="idClient" [(ngModel)]="filter.idClient">
                            <mat-select-filter [displayMember]="'name'" [array]="clients"
                                (filteredReturn)="clientsFiltered =$event"
                                [noResultsMessage]="'common.noResults' | translate"
                                [placeholder]="'common.selectFilterPlaceholder' | translate">
                            </mat-select-filter>
                            <mat-option [value]="null"> --- </mat-option>
                            <mat-option *ngFor="let itemClient of clientsFiltered" [value]="itemClient.id">
                                {{ itemClient.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'quoteList.filter.commercial.label' | translate }}</mat-label>
                            <mat-select #commercialSelect name="commercial"
                            [(ngModel)]="filter.idComercial">
                            <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                [array]="commercials" (filteredReturn)="commercialsFiltered =$event"
                                [noResultsMessage]="'common.noResults' | translate"
                                [placeholder]="'quoteEdit.general.commercial.label' | translate">
                            </mat-select-filter>
                            <mat-option [value]="null"> --- </mat-option>
                            <mat-option *ngFor="let itemCommercial of commercials"
                                [value]="itemCommercial.id">
                                {{ itemCommercial.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'quoteList.filter.quoteType.label' | translate }}</mat-label>
                        <mat-select name="idtype" [(ngModel)]="filter.idType">
                            <mat-option [value]="null"> --- </mat-option>
                            <mat-option *ngFor="let item of quoteTypes" [value]="item.id">
                                {{ 'quote.type.' + item.translate | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <mat-accordion>
                <mat-expansion-panel expanded="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'quoteList.filter.advanced.title' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'quoteList.filter.import.label' | translate }} >="
                                    name="import" [(ngModel)]="filter.totalAmount">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'quoteList.filter.wonLost.label' | translate }}</mat-label>
                                <mat-select name="wonLost" [(ngModel)]="filter.idStatus">
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let item of statuses" [value]="item.id">
                                        {{ 'quoteEdit.general.status.' + item.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'quoteList.filter.contact.label' | translate }}</mat-label>
                                <mat-select name="contactName"
                                    [(ngModel)]="filter.idContact">
                                    <mat-select-filter [displayMember]="'fullName'" [array]="contacts"
                                        (filteredReturn)="contactsFiltered =$event"
                                        [noResultsMessage]="'common.noResults' | translate"
                                        [placeholder]="'common.selectFilterPlaceholder' | translate">
                                    </mat-select-filter>
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let contact of contactsFiltered"
                                        [value]="contact.id">
                                        {{ contact.fullName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">
                    {{ 'button.filter' | translate }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>