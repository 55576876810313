import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoginService } from '../service/login.service';
import { SnackBarService } from '../service/snackBar.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private loginService: LoginService,
        private snackbarService: SnackBarService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        const authReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${this.loginService.getToken()}`
            }
        });

        return next.handle(authReq).pipe(catchError(err => this.handleAuthError(err)));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // Handle your auth error or rethrow

        // Si da un error y vamos contra el login, dejamos que sea la pantalla de login la que se encargue de manejarlo
        if (err != null && err.url != null && err.url.includes('login')) {
            return throwError(() => new Error());
        }

        // En caso de error de no autenticado, invalidamos la sesión
        if (err != null && (err.status === HttpStatusCode.Unauthorized || err.status === HttpStatusCode.Forbidden)) {
            const msg = 'No tiene permiso para realizar esta acción. Si piensa que es un error, contacte con su administrador del sistema';
            this.snackbarService.sendError(msg);
            this.loginService.clearSession();

            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to
            // handle it as well.
            return of(err.message);
        }
        return throwError(err);
    }

}
