<div mat-dialog-content class="quotit-theme">
    <div fxLayout="row" class="flexwrap">

        <!-- Provider -->
        <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <mat-label>{{ 'quoteEdit.dialog.outsource.provider.label' | translate }}</mat-label>
                <mat-select name="unit" [(ngModel)]="providerQuote.id" required
                    (selectionChange)="onProviderQuoteEmitter($event)">
                    <mat-option *ngFor="let item of providers" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ 'quoteEdit.dialog.outsource.provider.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <!-- Cost -->
        <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <input matInput type="number" [(ngModel)]="providerQuote.cost" name="cost" min="0" required
                    (change)="onProviderQuoteEmitter($event)"
                    placeholder="{{ 'quoteEdit.dialog.outsource.cost.label' | translate }}">
                <mat-error>
                    {{ 'quoteEdit.dialog.outsource.cost.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>