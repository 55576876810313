import { AuditDTO } from "./auditDTO";
import { DietDTO } from "./dietDTOs";
import { InterArea } from "./interArea";

export class InterAreaResult {
	name: string;
	idWorkcenter: number;
	dietDTOs: DietDTO[];
	areas : InterArea[] = [];
	sumDiet: number;
	auditDTOs: AuditDTO[]; 
	sumPriceCalibrations: number;
	sumCostCalibrations: number;
	sumTimeCalibrations: number;
}