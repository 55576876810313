import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/app/utils/constants';

@Pipe({ name: 'quoteTypeAir' })
export class QuoteTypeAirPipe implements PipeTransform {
  transform(idType: number, type: string) {
      switch(type){
        case Constants.SERVICE_TYPE_AIR: 
        return idType === Constants.QUOTE_TYPE_AIR || idType === Constants.QUOTE_TYPE_MIXED||idType === Constants.QUOTE_TYPE_CONSULTANCY;
        case Constants.SERVICE_TYPE_CONSULTANCY: 
        return idType === Constants.QUOTE_TYPE_CONSULTANCY || idType === Constants.QUOTE_TYPE_MIXED;
        case Constants.SERVICE_TYPE_THERMAL: 
        return idType === Constants.QUOTE_TYPE_THERMAL || idType === Constants.QUOTE_TYPE_MIXED ||idType === Constants.QUOTE_TYPE_CONSULTANCY;;
        case Constants.SERVICE_TYPE_CALIBRATES: 
        return idType === Constants.QUOTE_TYPE_CALIBRATES || idType === Constants.QUOTE_TYPE_MIXED;
        case Constants.SERVICE_TYPE_MIXED: 
        return idType === Constants.QUOTE_TYPE_MIXED;
        default: return false;
      }
      
  }
}