import { QuoteDTO, QuoteFilter } from '../model/quote';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class QuoteService {

    baseUrl = environment.quotit + '/quotes';
    constructor(private http: HttpClient) { }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllActive(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/';
        filter.active = true;
        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: QuoteFilter): Observable<any> {
        const url = environment.quotit + '/quotes/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    delete(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    deActivate(id: number): Observable<any> {
        const url = environment.quotit + `/quotes/deactivate/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(quote: QuoteDTO): Observable<any> {
        const url = environment.quotit + '/quotes/save';

        return this.http.post(url, quote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveQuoteTotalAmount(idQuote: number): Observable<any> {
        const url = environment.quotit + `/quotes/saveQuoteTotalAmount/${idQuote}`;

        return this.http.post(url, idQuote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getPdf(id: number): Observable<any> {
        const url = environment.quotit + `/report/try2/${id}`;

         return this.http.get<Blob>(url, {responseType : 
         'blob' as 'json'});
    }

    saveVersion(quoteId: number): Observable<any> {
        const url = `${this.baseUrl}/save-version`;
        return this.http.post(url, quoteId).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllByIdVersionControl(idVersionControl: number): Observable<any> {
        const url = `${this.baseUrl}/quote-by-idversion/${idVersionControl}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveVersionEnd(quote: QuoteDTO): Observable<any> {
        const url = `${this.baseUrl}/save-version-end`;
        return this.http.post(url, quote).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
