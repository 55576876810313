import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { User, UserFilter } from '../model/user';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

    currentLang: string;

    private usersSubject = new BehaviorSubject<User>(new User());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public user$ = this.usersSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    public get currentProfile(): User {
        return JSON.parse(localStorage.getItem('profile')) as User;
    }

    profile(): Observable<any> {
        const url = environment.quotit + '/profile/';

        const cthis = this;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)), map(this.saveProfile), map(u => {
            cthis.unreadSubject.next(u.unreadNotifications);
            return u;
        }));
    }

    save(user: User): Observable<any> {
        const url = environment.quotit + '/profile/';

        const cthis = this;

        return this.http.post(url, user).pipe(map(httpRes => HttpUtils.extractData(httpRes)), map(this.saveProfile), map(u => {
            cthis.unreadSubject.next(u.unreadNotifications);
            return u;
        }));
    }

    updateProfileSidebar(callback?: () => void): void {
        this.profile().subscribe((item: User) => {
            this.setProfileSidebar(item);

            if (callback) {
                callback();
            }
        });
    }

    setProfileSidebar(user: User): void {
        this.usersSubject.next(user);
    }

    changePassword(currentPassword: string, newPassword: string): Observable<any> {
        const url = environment.quotit + '/profile/changePassword';

        const data = { currentPassword, newPassword };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    changeProfile(idGroup: number, idApp: number): Observable<any> {
        const url = environment.quotit + '/profile/changeProfile';

        const data = { idGroup, idApp };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkUser(password: string): Observable<any> {
        const url = environment.quotit + '/users/checkUser';

        return this.http.post(url, { password }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    private saveProfile(res: User) {
        localStorage.setItem('profile', JSON.stringify(res));

        return res;
    }

    findAll(): Observable<any> {
        const url = environment.quotit + `/users/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllFilter(filter: UserFilter): Observable<any> {
        const url = environment.quotit + `/users/`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(username: string): Observable<any> {
        const url = environment.quotit + `/users/${username}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllByRole(roleId: number): Observable<any> {
        const url = environment.quotit + `/users/roleId/${roleId}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
