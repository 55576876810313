import * as XLSX from 'xlsx';
import * as moment from 'moment';

import { ArrayUtils } from './arrayUtils';
import { Client } from '../model/client';
import { NumberUtils } from './numberUtils';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { getJsDateFromExcel } from 'excel-date-to-js';
import { Calibration } from '../model/calibration';
import { EquipmentTypeService } from '../service/equipmentType.service';
import { VariableTypeService } from '../service/variableType.service';
import { CalibratesRangeService } from '../service/calibratesRange.service';
import { DateUtils } from './dateUtils';
import { VariableType } from '../model/VariableType';
import { EquipmentType } from '../model/equipment';
import { UnitService } from '../service/unit.service';
import { CalibrationSiteService } from '../service/calibrationSite.service';
import { CalibratesRange } from '../model/calibratesRange';
import { Unit } from '../model/unit';
import { CalibrationSite } from '../model/calibrationSite';

const daysToSec = 24 * 60 * 60;

export interface ConvertedFile {
    file: File;
    raw: boolean;
}

export class ExcelUtils {

    public static async excelToCalibration(file: File, equipmentTypeService: EquipmentTypeService,
        variableTypeService: VariableTypeService, calibratesRangeService: CalibratesRangeService, unitTypeService: UnitService,
        calibrationSiteService: CalibrationSiteService, translate: TranslateService):
        Promise<Calibration[]> {
        const data = await this.loadExcel(file, false);
        return this.loadCalibrations(data, equipmentTypeService, variableTypeService, calibratesRangeService, unitTypeService, calibrationSiteService, translate);
    }

    private static loadExcel(file: File, raw: boolean): Promise<any[][]> {
        return new Promise((resolve, reject) => {

            if (typeof file === 'string') {
                resolve(this.stringToWb(file, raw));
            } else {
                const reader: FileReader = new FileReader();

                reader.onload = (e: any) => resolve(this.stringToWb(e.target.result as string, raw));

                reader.onerror = (error: any) => reject(error);

                reader.readAsBinaryString(file);
            }
        });
    }

    private static stringToWb(str: string, raw: boolean): any[][] {
        let data: any[][];

        /* read workbook */
        const bstr: string = str;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', raw });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const options: XLSX.Sheet2JSONOpts = { header: 1, raw: true };

        /* save data */
        data = (XLSX.utils.sheet_to_json(ws, options));

        data = data.map((s: any[]) => {
            if (s.length === 1 && typeof s[0] === 'string') {
                const dat = s[0];
                s = dat.split(';');
            }

            return s as any[][];
        });

        return data;
    }

    private static async loadCalibrations(data: any[][], equipmentTypeService: EquipmentTypeService,
        variableTypeService: VariableTypeService, calibratesRangeService: CalibratesRangeService, unitTypeService: UnitService,
        calibrationSiteService: CalibrationSiteService, translate: TranslateService):
        Promise<Calibration[]> {
        const res: Calibration[] = [];

        const mapVariables: Map<string, number> = new Map();
        const mapEquipmentType: Map<string, number> = new Map();
        const mapCalibrateRange: Map<string, number> = new Map();
        const maplistUnitType: Map<string, number> = new Map();
        const maplistSite: Map<string, number> = new Map();

        const listVariables = await firstValueFrom(variableTypeService.findAll()) as VariableType[];
        const listEquipmentType = await firstValueFrom(equipmentTypeService.findAll()) as EquipmentType[];
        const listCalibrateRange = await firstValueFrom(calibratesRangeService.findAll()) as CalibratesRange[];
        const listUnitType = await firstValueFrom(unitTypeService.findAll()) as Unit[];
        const listSites = await firstValueFrom(calibrationSiteService.findAll()) as CalibrationSite[];

        listVariables.forEach(v => mapVariables.set((translate.instant('variableType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listEquipmentType.forEach(v => mapEquipmentType.set((translate.instant('equipmentType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listCalibrateRange.forEach(v => mapCalibrateRange.set((translate.instant('calibrateRange.'.concat(v.rangeDescription)) as string).toLowerCase(), v.id));
        listUnitType.forEach(v => maplistUnitType.set((translate.instant('unitType.'.concat(v.translate)) as string).toLowerCase(), v.id));
        listSites.forEach(v => maplistSite.set((translate.instant('calibrationSite.'.concat(v.translate)) as string)?.toLowerCase(), v.id));

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new Calibration();
            const nameEquipment = this.getDataFromCell(row, 0);
            let equipmentType: number;
            if (nameEquipment != null) {
                equipmentType = mapEquipmentType.get(nameEquipment.toLowerCase());
            }
            eq.equipmentType = equipmentType;

            eq.maker = this.getDataFromCell(row, 1);
            eq.model = this.getDataFromCell(row, 2);
            eq.serie = this.getDataFromCell(row, 3);
            eq.indicative = this.getDataFromCell(row, 4);
            eq.location = this.getDataFromCell(row, 5);

            const nameVariable = this.getDataFromCell(row, 6);
            let variableType: number;
            if (nameVariable != null) {
                variableType = mapVariables.get(nameVariable.toLowerCase());
            }
            eq.variableType = variableType;

            const nameUnit = this.getDataFromCell(row, 7);
            let unitType: number;
            if (nameUnit != null) {
                unitType = maplistUnitType.get(nameUnit.toLowerCase());
            }
            eq.unitType = unitType;

            const nameSite = this.getDataFromCell(row, 8);
            let siteType: number;
            if (nameSite != null) {
                siteType = maplistSite.get(nameSite.toLowerCase());
            }
            eq.calibrationSite = siteType;

            const nameRange = this.getDataFromCell(row, 9);
            let range: number;
            if (nameRange != null) {
                range = mapCalibrateRange.get(nameRange.toLowerCase());
            }
            eq.calibrationRange = range;

           

            if (eq.equipmentType == null) {
                console.error(`El tipo de equipo es nulo para la fila ${rowNum}`);
            } else if (eq.unitType == null) {
                console.error(`La unidad es nulo para la fila ${rowNum}`);
            } else if (eq.calibrationSite == null) {
                console.error(`El lugar es nulo para la fila ${rowNum}`);
            } else if (eq.variableType == null) {
                console.error(`El tipo de variable es nulo para la fila ${rowNum}`);
            } else if (eq.calibrationRange == null) {
                console.error(`El rango de la calibración es nulo para la fila ${rowNum}`);
            } else if (eq.serie == null) {
                console.error(`El número de serie de la calibración es nulo para la fila ${rowNum}`);
            } else if (eq.indicative == null) {
                console.error(`El indicativo interno de la calibración es nulo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        return res;
    }

    private static getDataFromCell(row: any[], index: number): string {
        let res: string = null;

        try {
            res = row[index] as string;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getNumberFromCell(row: any[], index: number): number {
        let res: number = null;

        try {
            res = row[index] as number;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getDateFromCell(row: any[], index: number): Date {
        let res: Date = null;
        const cell = row[index] as string;

        let colDay = 0;
        const colMonth = 1;
        let colYear = 2;

        try {
            // Si tiene el formato normal de fecha (dd/MM/yyyy), calculamos la fecha con ese formato,
            // Si por el contrario es un número, se trata del formato especial de Excel.
            if (cell.toString().includes('/')) {
                const isDateTime = cell.toString().includes(':');

                const dateStr = isDateTime ? cell.split(' ')[0] : cell;

                const dateSplit: string[] = dateStr.split('/');

                // Obtenemos los campos individuales para todas las partes de la fecha
                let dd = +dateSplit[colDay];

                if (dd > 100) {
                    colDay = 2;
                    colYear = 0;

                    dd = +dateSplit[colDay];
                }

                const mm = +dateSplit[colMonth] - 1;
                const yyyy = +dateSplit[colYear];

                // Creamos la fecha con Javascript
                if (isDateTime) {
                    const hourSplit: string[] = cell.split(' ')[1].split(':');

                    const hh = hourSplit[0] ? +hourSplit[0] : 0;
                    const min = hourSplit[1] ? +hourSplit[1] : 0;
                    const sec = hourSplit[2] ? +hourSplit[2] : 0;

                    res = new Date(yyyy, mm, dd, hh, min, sec);
                } else {
                    res = new Date(yyyy, mm, dd);
                }

            } else if (!isNaN(+cell)) {
                // Convertimos el dato Excel a fecha normal
                res = new Date(((+cell - (25567 + 1)) * 86400 * 1000) - 86400000);
            }
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static excelDatesToDate(dateOnly: Date | number | string, timeOnly: Date | number | string, rowIndex: number): Date {

        if (Object.prototype.toString.call(dateOnly) === '[object Date]') {
            // Caso tipo Date
            dateOnly = this.dateToDateStr(DateUtils.anyToDate(dateOnly));
        } else if (!isNaN(+dateOnly)) {
            // Caso número de Excel
            dateOnly = this.excelNumberToDateStr(+dateOnly);
        } else if (typeof dateOnly === 'string') {
            // Caso string
            dateOnly = this.excelStringToDateStr(dateOnly);
        }

        let dateOnlyStr: string = null;

        if (dateOnly) {
            dateOnlyStr = dateOnly.toString().trim();
        } else {
            return null;
        }

        if (Object.prototype.toString.call(timeOnly) === '[object Date]') {
            timeOnly = this.dateToHourStr(DateUtils.anyToDate(timeOnly));
        } else if (!isNaN(+timeOnly)) {
            timeOnly = this.excelNumberToTimeStr(+timeOnly);
        } else if (typeof timeOnly === 'string') {
            // Caso string
            timeOnly = this.excelStringToTimeStr(timeOnly);
        }

        let timeOnlyStr: string = null;

        if (timeOnly) {
            timeOnlyStr = timeOnly.toString().trim();
        } else {
            return null;
        }

        const dateStr = `${dateOnlyStr}  ${timeOnlyStr}` + ' +0:00';
        let date = moment(dateStr, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();

        if (isNaN(date.getTime())) {
            console.error(`No se ha reconocido la fecha ${dateOnlyStr}, hora ${timeOnlyStr}. Fila: ${(rowIndex + 1)}`);
            date = null;
        }

        return date;
    }

    private static stringToDate(str: string): Date {
        let date = moment(str, 'DD/MM/YYYY HH:mm:ss').toDate();

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD/MM/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD-MMM-YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = null;
        }

        return date;
    }

    private static excelNumberToDateStr(num: number): string {
        const rawDate: Date = new Date((num - 25569) * 86400 * 1000);

        const date: Date = new Date(rawDate.toDateString());

        return this.dateToDateStr(date);
    }

    private static excelStringToDateStr(str: string): string {
        let isDDMM = false;
        try {
            isDDMM = isDDMM || moment(str, 'DD/MM/YYYY', true).isValid();
            isDDMM = isDDMM || moment(str, 'DD/MM/YY', true).isValid();
        } catch (e) {

        }

        const data = str.split('/');

        if (data.length < 2) {
            return null;
        }

        let day = String(data[isDDMM ? 0 : 1]);
        let month = String(data[isDDMM ? 1 : 0]);
        let year = String(data[2]);

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (year.length < 3) {
            year = '20' + year;
        }

        return `${month}/${day}/${year}`;
    }

    private static excelNumberToDateTimeStr(num: number): string {
        const date = getJsDateFromExcel(num);
        return this.dateTimeToHourStr(new Date(date));
    }

    private static excelNumberToTimeStr(num: number): string {
        num = num * daysToSec;

        const date = new Date(1900, 0, 1);
        date.setSeconds(date.getSeconds() + num);

        return this.dateToHourStr(date);
    }

    private static dateToDateStr(date: Date): string {
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        const year = String(date.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return `${day}/${month}/${year}`;
    }

    private static dateToHourStr(date: Date): string {
        let hour = `${date.getHours()}`;
        let minutes = `${date.getMinutes()}`;
        let seconds = `${date.getSeconds()}`;

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static excelStringToTimeStr(str: string): string {
        str = str.replace(' AM', '');

        const isAM = str.includes(' PM') && !str.startsWith('12:');

        str = str.replace(' PM', '');

        const data = str.split(':');

        if (data.length < 2) {
            return null;
        }

        let hour = data[0];
        let minutes = data[1];
        let seconds = data[2];

        if (isAM) {
            hour = ((+hour) + 12).toString();
        }

        if (hour.includes('24')) {
            hour = '00';
        }

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static dateTimeToHourStr(date: Date): string {
        return this.dateToDateStr(date) + ' ' + this.dateToHourStr(date);
    }

    private static cellToData(row: string[], column: number): string {
        if (column < 0) {
            return null;
        }

        return this.getDataFromCell(row, column);
    }

}
