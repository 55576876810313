import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export class TranslateUniversalLoader implements TranslateLoader {

  map = new Map<string, any>();

  implementedLangs = ['es', 'en', 'none'];

  constructor(private http: HttpClient, private prefix: string = 'i18n', private suffix: string = '.json') {
    this.implementedLangs.forEach(lang => this.loadLang(lang).subscribe((res: any[]) => {
      this.map.set(lang, res);
    }, err => {
      console.error(`No se ha podido cargar el idioma ${lang}. Error: ${err.message}`);
    }));
  }

  /**
   * Gets the translations from the server
   *
   * @param lang The lang
   */
  public getTranslation(lang: string): Observable<any> {
    return new Observable(observer => {
      let json = this.map.get(lang);

      if (json == null) {
        json = this.map.get('none');
      }

      if (json == null) {
        this.loadLang(lang).subscribe((res: any[]) => {
          this.map.set(lang, res);
          observer.next(res);
          observer.complete();
        }, err => {
          console.error(`No se ha podido cargar el idioma ${lang}. Error: ${err.message}`);
          observer.next(null);
          observer.complete();
        });
      } else {
        observer.next(json);
        observer.complete();
      }

    });
  }

  private loadLang(lang: string): Observable<any> {
    return this.http.get(`${location.protocol}//${location.host}/${this.prefix}${lang}${this.suffix}`, { responseType: 'text' })
    .pipe(map(this.createObject));
  }

  private createObject(str: any) {
    const result = {};

    str.split('\n').forEach((line: string) => {
      if (line.includes('=') && !line.startsWith('#')) {
        const keyData = line.split('=');
        const key = keyData[0].trim();
        const data = keyData[1].trim();

        result[key] = data;
      }
    });

    return result;
  }
}
