import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Area } from 'src/app/model/area';
import { AreaPrice } from 'src/app/model/areaPrice';
import { AuditType } from 'src/app/model/auditType';
import { Client } from 'src/app/model/client';
import { InterArea } from 'src/app/model/interArea';
import { InterAreaResult } from 'src/app/model/interAreaResult';
import { QuoteDTO } from 'src/app/model/quote';
import { AuditTypeService } from 'src/app/service/audit-type.service';
import { ClientService } from 'src/app/service/client.service';
import { DietTravelService } from 'src/app/service/dietTravel.service';
import { LoadingService } from 'src/app/service/loading.service';
import { QuoteService } from 'src/app/service/quote.service';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { NumberUtils } from 'src/app/utils/numberUtils';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss'],
})
export class QuoteDetailComponent implements OnInit {

  @Input() quote: QuoteDTO;
  
  isConsultancy : boolean;
  isThermal: boolean;
  isCalibration: boolean;

  areas: AreaPrice[] = [];
  interArea: InterArea[] = [];
  client: Client = new Client();
  dietAmount = 0;
  results: InterAreaResult[] = [];
  auditTypes: AuditType[];

  productionCost: number;
  gainsPercentaje: number;
  totalCourses = 0;
  totalCostThermal = 0;
  totalSaleThermal = 0;


  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private dietTravelService: DietTravelService,
    private quoteService: QuoteService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    public loader: LoadingService,
    private auditTypeService: AuditTypeService
  ) { }

  ngOnInit(): void {
    this.isConsultancy = this.quote.idType == 4;
    this.isThermal = this.quote.idType === 2;
    this.isCalibration = this.quote.idType === 3;

    this.loader.show();
    this.results.push(new InterAreaResult());
    this.route.queryParams.subscribe(params => {
      let id;
      id = this.quote.id;
      this.quoteService.findOne(id).subscribe((q: any) => {
        this.quote = q;
        this.testTypeEquipmentConfigService.totalCostTestPerArea(id).subscribe((res: InterAreaResult[]) => {
          this.results = res;
          this.results.forEach(result => {
            result.areas.forEach(area => {
              area.totalCostTestPerArea += this.getTotalSaleConsultor(area);
              area.totalDays = this.getAreaWorkday(area.totalTimePerArea);
              area.totalCostConsultor = this.getTotalCostConsultor(area);
              area.totalTimeConsultor = this.getTimeConsultor(area);
              area.totalSaleConsultor = this.getTotalSaleConsultor(area);
            });
            this.loader.hide();
          });

          this.productionCost = this.getTotalProductionPrice();
          this.gainsPercentaje = this.getPercentajeGains();
          this.totalCourses = this.getCoursesTotal();
          this.totalCostThermal = this.getThermalCost();
          this.totalSaleThermal = this.getThermalSale();
        });
        this.clientService.findOne(q.idClient).subscribe((res: Client) => {
          this.client = res;
          this.loader.hide();
        });
        if (this.quote.idClient != null) {
          this.loader.hide();
        }

        this.auditTypeService.findAll().subscribe((res: AuditType[]) => {
          this.auditTypes = res;
        });

        this.getTotalAmountDietGroupByQuote();
      });
    });

  }

  getTotalProductionPrice(): number {
    let price = 0;
    this.results.forEach(result => {
      result.areas.forEach(area => {
        price += area.costProduction;
      })
    });
    return price;
  }

  getPercentajeGains(): number {
    const total = this.quote.totalAmount- this.getTotalAmountDietGroupByQuote();
    let price = (total-this.quote.totalCost)*100/total;    
    return NumberUtils.round(price,2);
  }

  getCoursesTotal(): number {
    this.quote.workcenters.forEach(wc => {
        wc.courses.forEach(course => {
          this.totalCourses += course.price;
        });
    });
    return this.totalCourses;
  }

  getHours(minutes: number): number {
    return Math.ceil(NumberUtils.round((minutes/60)))
  }

  private getAreaWorkday(hours: number): number {
    return NumberUtils.round((hours/60)/8,2);
  }

  private getTotalAmountDietGroupByQuote(): number {
    this.dietTravelService.totalAmountDietGroupByQuote(this.quote.id).subscribe(res => {
      this.dietAmount = res.sumDiets;
    });
    return this.dietAmount;
  }

  private getTotalCostConsultor(area: InterArea): number {
    let cost = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id == area.id);
    });
    if(areaFinded != null){
      areaFinded.consultancyProfiles.forEach(profile => {
        cost +=profile.cost;
      });
      areaFinded.equipments.forEach( eq => {
        eq.qualifications.forEach( quali => {
          cost += quali.cost;
        })
      });
      cost += areaFinded.totalConsultancyCostRoom;

      areaFinded.compressedGases.forEach( gas => {
        gas.qualifications.forEach( quali => {
          cost += quali.cost;
        })
      });
    }
    return cost;
  }

  private getTotalSaleConsultor(area: InterArea): number {
    let price = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id == area.id);
    });
    if(areaFinded != null){
      areaFinded.consultancyProfiles.forEach(profile => {
        price +=profile.price;
      });
      areaFinded.equipments.forEach( eq => {
        eq.qualifications.forEach( quali => {
          price += quali.price;
        })
      });
      price += areaFinded.totalConsultancyRoom;
      
      areaFinded.compressedGases.forEach( gas => {
        gas.qualifications.forEach( quali => {
          price += quali.price;
        })
      });
    }
    return price;
  }

  private getTimeConsultor(area: InterArea): number {
    let time = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id == area.id);
    });
    if(areaFinded != null){
      areaFinded.consultancyProfiles.forEach(profile => {
        time +=profile.hours;
      });
    }
    areaFinded.equipments.forEach( eq => {
      eq.qualifications.forEach( quali => {
        time += quali.hours;
      })
    });
    time += areaFinded.totalConsultancyTimeRoom;

    areaFinded.compressedGases.forEach( gas => {
      gas.qualifications.forEach( quali => {
        time += quali.hours;
      })
    });
    return time;
  }

  showAuditName(idType: number):string {
    let name = '';
    this.auditTypes.forEach(e => {
      if (e.id === idType) {
        name = e.translate;
      }
    });
    return 'quoteEdit.general.audit.'.concat(name);
  }

  private getThermalCost(): number {
    this.quote?.workcenters?.forEach(wc => {
        wc?.equipmentThermal?.forEach(equipment => {
          equipment.test.forEach(test => {
            this.totalCostThermal += test.cost;
          });
        });
    });

    return this.totalCostThermal;
  }

  private getThermalSale(): number {
    this.quote?.workcenters?.forEach(wc => {
        wc?.equipmentThermal?.forEach(equipment => {
          equipment.test.forEach(test => {
            this.totalSaleThermal += test.price;
          });
        });
    });

    return this.totalSaleThermal;
  }
  
  
}


