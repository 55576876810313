import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { Course } from 'src/app/model/course';
import { CourseService } from 'src/app/service/course.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html'
})
export class CourseEditComponent implements OnInit {

  form: FormGroup;
  course: Course
  private destroy$ = new Subject<void>();
  
  constructor(
    fb: FormBuilder,
    private route: ActivatedRoute,
    private courseService: CourseService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    public snackBarService: SnackBarService
    ) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params['id'] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }
      this.course = new Course();
      if (+id !== 0) {
        this.courseService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: Course) => {
          if (result != null) {
            this.course = result;
          }else{
            this.course = null;
          }
          
          if (this.course == null) {
            this.cancel();
          }
          
        });
      }
    });
      
    this.course = new Course();
    this.form = fb.group({
      name: [this.course.name]
    });
  }  

  ngOnInit(): void {
  }

  save(): void {
    const errors: string[] = [];
    const isNew = this.course.id == null;

    if (this.course.name == null) {
      errors.push(this.translate.instant('courseEdit.dialog.course.name.error') as string);
    }

    if (errors.length === 0) {

      this.courseService.save(this.course).pipe(takeUntil(this.destroy$)).subscribe((res: Course) => {
        this.course = res;
        this.reloadPage();

        if (isNew) {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

          void this.router.navigateByUrl(`/course?id=${res.id}`);
        } else {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
        }

      }, err => {
        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else if (isNew) {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.create.error') as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.update.error') as string);
        }

      });
    }
  }

  cancel(): void {
    void this.router.navigateByUrl('/courses');
  }

  private reloadPage(idCourse = this.course.id) {
    if (idCourse == null) {
      idCourse = this.course.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`/course?id=${idCourse}`);
  }

}
