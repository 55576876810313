<ng-template mat-tab-label>{{ 'quoteEdit.general.title' | translate }} </ng-template>

<mat-tab-group #quoteTabs (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="selectedTabIndex">
    <mat-tab #quoteTab label="{{ 'quoteEdit.general.title' | translate }}">
        <form>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'quoteEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" class="flexwrap">

                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <!-- Usuariologado -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="userfullName" [(ngModel)]="quoteDTO.userfullName"
                                                disabled
                                                placeholder="{{ 'quoteEdit.general.quoteNumber.userfullName' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.timezone' | translate }}</mat-label>
                                            <mat-select #clientSelect required name="idTimeZone"
                                                [(ngModel)]="quoteDTO.idTimeZone">
                                                <mat-option *ngFor="let item of timeZones" [value]="item.id">
                                                    {{ item.zone }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.idTimeZone' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.quoteType' | translate }}</mat-label>
                                            <mat-select required name="quoteType" [(ngModel)]="quoteDTO.idType"
                                                (selectionChange)="onQuoteTypeChange($event)">
                                                <mat-option *ngFor="let item of quoteTypes" [value]="item.id">
                                                    {{ 'quote.type.'+item.translate | translate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.quoteType.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <!-- Nº DE LA OFERTA -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="quoteNumber" [(ngModel)]="quoteDTO.quoteNumber"
                                                required
                                                placeholder="{{ 'quoteEdit.general.quoteNumber.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <div fxFlex="50" fxFlex.lt-md="100%">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="status" [(ngModel)]="quoteDTO.translateStatus"
                                                    disabled placeholder="{{ 'quoteEdit.general.status'| translate }}">
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="50" fxFlex.lt-md="100%">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="nVersion" [(ngModel)]="quoteDTO.numVersion"
                                                    disabled
                                                    placeholder="{{ 'quoteEdit.general.numVersion'| translate }}">
                                            </mat-form-field>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <!-- CLIENTE -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.client.label' | translate }}</mat-label>
                                            <mat-select #clientSelect required name="idClient"
                                                [(ngModel)]="quoteDTO.idClient"
                                                (selectionChange)="onClientChange($event)">
                                                <mat-select-filter [displayMember]="'name'" [array]="clients"
                                                    (filteredReturn)="clientsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let itemClient of clientsFiltered"
                                                    [value]="itemClient.id">
                                                    {{ itemClient.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.client.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- CONTACTO -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.contactName.label' | translate }}
                                            </mat-label>
                                            <mat-select required name="contactName"
                                                [(ngModel)]="quoteDTO.idClientContact"
                                                (selectionChange)="ontContactChange($event)">
                                                <mat-select-filter [displayMember]="'fullName'" [array]="contacts"
                                                    (filteredReturn)="contactsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let contact of contactsFiltered"
                                                    [value]="contact.id">
                                                    {{ contact.fullName }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.contact.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">

                                    <!-- EMAIL DEL CLIENTE -->
                                    <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="contact != null">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="email" [(ngModel)]="contact.email" disabled
                                                placeholder="{{ 'quoteEdit.general.email.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <!-- Direccion -->
                                    <div fxFlex="40" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="address" [(ngModel)]="client.address" disabled
                                                placeholder="{{ 'quoteEdit.general.address.label' | translate }}">
                                        </mat-form-field>
                                    </div>

                                    <!-- CIUDAD DEL CLIENTE -->
                                    <div fxFlex="20" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="city" [ngModel]="client.city" disabled
                                                placeholder="{{ 'quoteEdit.general.city.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <!-- DESCRIPCION DEL PROYECTO -->
                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="descriptionService" required
                                                [(ngModel)]="quoteDTO.descriptionService"
                                                placeholder="{{ 'quoteEdit.general.descriptionService.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">

                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                        <!-- ALCANCE -->
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="scope" [(ngModel)]="quoteDTO.scope" required
                                                placeholder="{{ 'quoteEdit.general.scope.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <!-- METODO DE PAGO -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.paymentMethod.label' | translate }}
                                            </mat-label>
                                            <mat-select #contactSelect required name="idPaymentMethod"
                                                [(ngModel)]="quoteDTO.idPaymentMethod">
                                                <mat-option *ngFor="let payment of payments" [value]="payment.id">
                                                    {{ payment.translation | translate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.payment.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- DESCUENTO INCREMENTO -->
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario" *ngIf="showIncremet()">

                                            <mat-select name="idDiscountIncrease"
                                                [(ngModel)]="quoteDTO.valueDiscountIncrease"
                                                placeholder="{{ 'quoteEdit.general.discountIncrease.label' | translate }}"
                                                (selectionChange)="onDiscountIncreaseChange($event)">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of valuesDiscountIncrease" [value]="item">
                                                    {{ 'quoteEdit.general.'+item | translate}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario" *ngIf="quoteDTO.valueDiscountIncrease">
                                            <input matInput required type="number"
                                                [(ngModel)]="quoteDTO.discountIncrease" name="discountIncrease"
                                                placeholder="{{ 'quoteEdit.general.percentage.label' | translate }}">
                                            <mat-error>
                                                {{ 'quoteEdit.general.discountIncrease.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <!-- COMERCIAL -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.commercial.label' | translate }}
                                            </mat-label>
                                            <mat-select #commercialSelect required name="commercial"
                                                [(ngModel)]="quoteDTO.idCommercial">
                                                <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                                    [array]="commercials" (filteredReturn)="commercialsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'quoteEdit.general.commercial.label' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let itemCommercial of commercials"
                                                    [value]="itemCommercial.id">
                                                    {{ itemCommercial.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.commercial.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- CHECK BOXS -->
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <!-- CHECK BOX TRABAJO EXCEPCIONAL -->
                                        <div fxFlex="35" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.exclusiveUrgentService' | translate }}"
                                                [(ngModel)]="quoteDTO.exclusiveUrgentService"
                                                name="exclusiveUrgentService">
                                                {{ 'quoteEdit.general.button.exclusiveUrgentService' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX CON PROTOCOLOS -->
                                        <div fxFlex="25" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.withProtocol' | translate }}"
                                                [(ngModel)]="quoteDTO.withProtocol" name="quoteWithProt">
                                                {{ 'quoteEdit.general.button.withProtocol' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX CON RECURRENTE -->
                                        <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.recalculate' | translate }}"
                                                [(ngModel)]="quoteDTO.recalculate" name="quoteRecalculate">
                                                {{ 'quoteEdit.general.button.recalculate' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX IMPLANT -->
                                        <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.implant' | translate }}"
                                                [(ngModel)]="quoteDTO.implant" name="quoteImplant">
                                                {{ 'quoteEdit.general.button.implant' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX MOSTRAR DIETAS 
                                        <div fxFlex="25" fxFlex.lt-md="100%">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.showDiets' | translate }}"
                                                [(ngModel)]="quoteDTO.showDiets" name="quoteShowDiets">
                                                {{ 'quoteEdit.general.button.showDiets' | translate }}
                                            </mat-checkbox>
                                        </div> -->
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </mat-card-content>
            </mat-card>
            <mat-expansion-panel class="overflowX">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.workcenter.title' | translate }}

                            <button mat-icon-button color="accent" (click)="openDialogWorkcenter()"
                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- WorkCenters -->
                <mat-card class="card">
                    <mat-card-content>

                        <mat-tab-group #workcenterTabs (selectedTabChange)="onWorkcenterTabChanged($event)">
                            <mat-tab *ngFor="let workcenter of quoteDTO.workcenters; let indexWC = index">

                                <ng-template mat-tab-label>
                                    {{ workcenter.name }}
                                    <button mat-icon-button color="accent" *ngIf="workcenterTab === indexWC"
                                        (click)="openDialogEditWorkcenter(indexWC)"
                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>

                                    <button mat-icon-button color="accent" *ngIf="workcenterTab === indexWC"
                                        (click)="deleteWorkcenter(indexWC)"
                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </ng-template>
                                <br />
                                <!-- Diets -->
                                <mat-toolbar color="secondary">
                                    {{ 'quoteEdit.diet.title' | translate }}

                                    <button mat-icon-button color="accent" (click)="openDialogDiet(workcenter)"
                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <mat-list>
                                    <mat-list-item *ngFor="let diet of workcenter.dietDTOs; let indexDiet = index">

                                        <div>
                                            {{ showDietName(diet.idDietTravel) | translate }}
                                            {{ diet.amount }}
                                            <button mat-icon-button color="accent"
                                                (click)="openDialogEditDiet(indexWC,indexDiet)">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                (click)="deleteWorkDiet(indexWC,indexDiet)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>

                                <!-- Equipment Thermal -->
                                <mat-toolbar color="secondary"
                                    *ngIf="(quoteDTO.idType | quoteTypeAir: isThermalService)">
                                    {{ 'quote.EquipmentThermal.title' | translate }}
                                    <button mat-icon-button color="accent"
                                        (click)="openDialogEquipmentThermal(indexWC)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <mat-list *ngIf="(quoteDTO.idType | quoteTypeAir: isThermalService)">
                                    <mat-list-item
                                        *ngFor="let equipmentThermal of workcenter.equipmentThermal; let indexThermal = index">
                                        <div>
                                            {{ equipmentThermal.equipment }}

                                            {{'(S/N:'}}{{ equipmentThermal.serialNumber || "NA" }}{{')'}}
                                            {{'(Id.'}} {{ equipmentThermal.identificator || "NA" }}{{')'}}
                                            {{'('}}{{ 'quoteEdit.equipment.thermal.location' | translate }}{{ equipmentThermal.location }}{{')'}}
                                            <button mat-icon-button color="accent"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="editEquipmentThermal(indexWC,indexThermal)">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                            <button mat-icon-button color="accent"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="deleteEquipmentThermal(indexWC,indexThermal)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>

                                <!-- Calibrations -->
                                <mat-toolbar color="secondary"
                                    *ngIf="(quoteDTO.idType | quoteTypeAir: isCalibratesService)">
                                    {{ 'quote.calibrations.title' | translate }}
                                    <button mat-icon-button color="accent"
                                        (click)="openDialogCalibration(indexWC)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                    <button mat-icon-button color="accent"
                                        (click)="openDialogUploadMasiveCalibration(indexWC)">
                                        <mat-icon>upload</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <mat-list *ngIf="(quoteDTO.idType | quoteTypeAir: isCalibratesService)">
                                    <mat-list-item
                                        *ngFor="let calibration of workcenter.calibrations; let indexCalibration = index">
                                        <div>

                                            {{ showCalibrationName(calibration) | translate }}
                                            <button mat-icon-button color="accent" matTooltip="{{ 'button.duplicate' | translate }}"

                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="cloneCalibration(indexWC,indexCalibration)">
                                                <mat-icon>control_point_duplicate</mat-icon>
                                            </button>
                                            <button mat-icon-button color="accent" matTooltip="{{ 'button.edit' | translate }}"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="openDialogEditCalibration(indexWC,indexCalibration)">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                            <button mat-icon-button color="accent" matTooltip="{{ 'button.delete' | translate }}"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="deleteCalibration(indexWC,indexCalibration)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                                <!-- BOTON AÑADIR TRAINING -->
                                <mat-toolbar color="secondary" *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                    {{ 'quoteEdit.general.training.title' | translate }}

                                    <button mat-icon-button color="accent" (click)=" openDialogTraining(indexWC)"
                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>

                                <mat-list *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                    <mat-list-item *ngFor="let course of workcenter.courses; let indexCourse = index">
                                        <div>
                                            {{ showCourseName(course.idType) | translate }}
                                            {{ course.price }}
                                            <button mat-icon-button color="accent"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="openDialogEditTraining (indexWC,indexCourse)">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                (click)="deleteTraining(indexWC,indexCourse)">

                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                                <!-- Audit -->
                                <mat-toolbar color="secondary" *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                    {{ 'quoteEdit.general.audit.title' | translate }}

                                    <button mat-icon-button color="accent" (click)="openDialogAudit(workcenter)"
                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <mat-list *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                    <mat-list-item *ngFor="let audit of workcenter.auditDTOs; let indexAudit = index">
                                        <div>
                                            {{ showAuditName(audit.auditType) | translate }}
                                            <button mat-icon-button color="accent"
                                                (click)="openDialogEditAudit(indexWC,indexAudit)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                (click)="deleteWorkAudit(indexWC,indexAudit)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>

                                <mat-toolbar color="secondary" *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                    {{ getTranslationFromQuoteType(quoteDTO.idType) | translate }}
                                    <button mat-icon-button color="accent" (click)="openDialogArea(indexWC)"
                                        [disabled]="(quoteDTO.idStatus | quoteStatus: 'signed')">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>

                                <mat-tab-group #areaTabs (selectedTabChange)="onAreaTabChanged($event)"
                                    *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                    <!--AREAS -->
                                    <mat-tab *ngFor="let area of workcenter.areas; let indexArea = index">

                                        <ng-template mat-tab-label>
                                            {{ area.name }}

                                            <button mat-icon-button color="accent" *ngIf="indexArea>-1"
                                                (click)="openDialogEditArea(indexWC, indexArea)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="indexArea > -1"
                                                (click)="deleteArea(indexWC, indexArea)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </ng-template>

                                        <!-- BOTON AÑADIR EQUIPOS DE AIRE -->
                                        <mat-toolbar color="secondary"
                                            *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            {{ 'quoteEdit.general.equipmentAir.title' | translate }}

                                            <button mat-icon-button color="accent"
                                                (click)="openDialogEquipment(indexWC, indexArea)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </mat-toolbar>
                                        <!-- EQUIPOS AIRE -->
                                        <mat-accordion *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            <mat-expansion-panel class="overflowX"
                                                *ngFor="let equipment of area.equipments; let indexEquipment = index"
                                                (afterExpand)="equipmentTab = indexEquipment"
                                                (closed)="equipmentTab = null">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <div style="margin: auto 0;display: block;">
                                                            {{ showEquipment(equipment) }}
                                                            -
                                                            {{ ('equipmentType.' + getEquipmentType(equipment)) |
                                                            translate
                                                            }}
                                                        </div>
                                                        <!-- EDITAR EQUIPOS AIRE -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="equipmentTab === indexEquipment"
                                                            (click)="openDialogEditEquipment(indexWC,indexArea,indexEquipment)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                        </button>

                                                        <!-- ELIMINAR EQUIPOS AIRE -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="equipmentTab === indexEquipment"
                                                            (click)="deleteEquipment(area,indexEquipment)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                        </button>
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="equipmentTab === indexEquipment && false"
                                                            (click)="duplicateEquipment(indexArea, indexEquipment)">
                                                        </button>
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngIf="equipment.countTestRest != null">
                                                        {{ "quoteEdit.dialog.equipment.count.label" | translate }}
                                                    </div>
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngIf="equipment.countTestWorking!= null">
                                                        {{ "quoteEdit.dialog.equipment.countWorking.label" | translate
                                                        }}
                                                    </div>
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngFor="let test of equipment.tests">
                                                        {{ getTestName(test.idType) | translate }}
                                                    </div>
                                                </div>
                                                <br />
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="15" fxFlex.lt-md="100%"
                                                        *ngFor="let qualification of equipment.qualifications">
                                                        {{ getQualificationName(qualification.idType) | translate }}
                                                    </div>
                                                </div>

                                            </mat-expansion-panel>
                                        </mat-accordion>
                                        <br />
                                        <!-- BOTON AÑADIR SALAS DE AIRE -->
                                        <mat-toolbar color="secondary"
                                            *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            {{ 'quoteEdit.general.roomAir.title' | translate }}

                                            <button mat-icon-button color="accent"
                                                (click)="openDialogRoom(indexWC, indexArea)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </mat-toolbar>
                                        <!-- SALAS AIRE -->
                                        <mat-accordion *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            <mat-expansion-panel class="overflowX"
                                                *ngFor="let room of area.rooms; let roomIndex = index"
                                                (afterExpand)="roomTab = roomIndex" (closed)="roomTab = null">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <div style="margin: auto 0;display: block;">
                                                            {{room.name}}
                                                        </div>
                                                        <!-- EDITAR SALAS -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="roomTab === roomIndex"
                                                            (click)="openDialogEditRoom(indexWC,indexArea,roomIndex)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                        </button>

                                                        <!-- ELIMINAR SALAS -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="roomTab === roomIndex"
                                                            (click)="deleteRoom(area,roomIndex)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                        </button>
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="roomTab === roomIndex && false"
                                                            (click)="duplicateEquipment(indexWC,indexArea,roomIndex)">
                                                        </button>
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <div fxFlex="10" fxFlex.lt-md="100%" *ngIf="room.countTest != null">
                                                    Contaje
                                                </div>
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngIf="room.countTestRest != null">
                                                        {{ "quoteEdit.dialog.equipment.count.label" | translate }}
                                                    </div>
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngIf="room.countTestWorking!= null">
                                                        {{ "quoteEdit.dialog.equipment.countWorking.label" | translate
                                                        }}
                                                    </div>
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngFor="let test of room.tests">
                                                        {{ getTestName(test.idType) | translate }}
                                                    </div>
                                                </div>
                                                <br />
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="15" fxFlex.lt-md="100%"
                                                        *ngFor="let qualification of room.qualifications">
                                                        {{ getQualificationName(qualification.idType) | translate }}
                                                    </div>
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                        <br />
                                        <!-- BOTON AÑADIR GASES DE AIRE -->
                                        <mat-toolbar color="secondary"
                                            *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            {{ 'quoteEdit.general.compressedGas.title' | translate }}

                                            <button mat-icon-button color="accent"
                                                (click)="openDialogGas(indexWC, indexArea)"
                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </mat-toolbar>
                                        <!-- GASES AIRE -->
                                        <mat-accordion *ngIf="(quoteDTO.idType | quoteTypeAir: isAirService)">
                                            <mat-expansion-panel class="overflowX"
                                                *ngFor="let gas of area.compressedGases; let gasIndex = index"
                                                (afterExpand)="gasTab = gasIndex" (closed)="gasTab = null">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <div style="margin: auto 0;display: block;">
                                                            {{showGasName(gas)}}
                                                        </div>
                                                        <!-- EDITAR GASES -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="gasTab === gasIndex"
                                                            (click)="openDialogEditGas(indexWC,indexArea, gasIndex)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                        </button>
                                                        <!-- ELIMINAR GASES -->
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="gasTab === gasIndex"
                                                            (click)="deleteGas(area, gasIndex)"
                                                            [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                            <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                        </button>
                                                        <button mat-icon-button color="accent"
                                                            *ngIf="asTab === gasIndex && false"
                                                            (click)="duplicateEquipment(indexWC,indexArea, gasIndex)">
                                                        </button>
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="10" fxFlex.lt-md="100%"
                                                        *ngIf="gas.countTestRest != null">
                                                        {{ "testType.particleCount" | translate }}
                                                    </div>
                                                    <div fxFlex="15" fxFlex.lt-md="100%" *ngFor="let test of gas.tests">
                                                        {{ getTestName(test.idType) | translate }}
                                                    </div>
                                                </div>
                                                <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                    <div fxFlex="15" fxFlex.lt-md="100%"
                                                        *ngFor="let qualification of gas.qualifications">
                                                        {{ getQualificationName(qualification.idType) | translate }}
                                                    </div>
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                        <br />
                                        <!-- BOTON AÑADIR CONSULTORIA -->
                                        <mat-toolbar color="secondary"
                                            *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                            {{ 'quoteEdit.general.counsultory.title' | translate }}
                                        </mat-toolbar>

                                        <!-- ALCANCE CONSULTORIA -->
                                        <!-- TEXT AREA-->
                                        <div class="form-outline w-75 mb-4"
                                            *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                            <div fxFlex="50" fxLayout="column" fxFlex.lt-md="100%"
                                                style="height: min-content;">
                                                <div fxFlex="100" style="padding: 10px">
                                                    <quill-editor [(ngModel)]="area.scope" name="wysiwygContent"
                                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')"
                                                        id="wysiwygContent"></quill-editor>
                                                </div>
                                                <div fxFlex="100">
                                                    <button mat-icon-button color="accent"
                                                        (click)="addConsultancyProfile(indexWC, indexArea)"
                                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                        <mat-icon style="font-size: 20px;">add</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container" *ngIf="(quoteDTO.idType | quoteTypeAir: isConsultancy)">
                                            <div class="row"
                                                *ngFor="let profile of area?.consultancyProfiles; let profileIndex = index">
                                                <div div fxFlex="100" fxFlex.lt-md="100%">
                                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                                        {{ showProfileName(profile.idProfile) | translate }}
                                                    </div>
                                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                                        {{ profile.hours }}h.
                                                    </div>
                                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                                        {{profile.price}}€
                                                    </div>
                                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                                        {{profile.implant}}
                                                    </div>
                                                    <!-- EDITAR CONSULTORIA -->
                                                    <button mat-icon-button color="accent"
                                                        (click)="openDialogEditConsultancyProfile(indexWC, indexArea, profileIndex)"
                                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                        <mat-icon>mode_edit</mat-icon>
                                                    </button>
                                                    <!-- ELIMINAR CONSULTORIA -->
                                                    <button mat-icon-button color="accent"
                                                        (click)="deleteConsultancyProfile(indexWC, indexArea, profileIndex)"
                                                        [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                        <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </mat-tab>
                                </mat-tab-group>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel class="overflowX" *ngIf="quoteDTO.id !=null">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.general.tab.title' | translate }}
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-quote-detail #quoteDetailComponent [quote]="quoteDTO"></app-quote-detail>
            </mat-expansion-panel>

            <mat-card class="card">
                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="confirmationHours()" *ngIf="showSave()">
                        {{ 'button.save' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="advance()"
                        *ngIf="(quoteDTO.idStatus| quoteStatus: 'preparation') && quoteDTO.id != null">
                        {{ 'button.advance' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="send()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'signed')">
                        {{ 'button.send' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="retreat()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'signed')">
                        {{ 'button.retreat' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="sendWon()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'sent')">
                        {{ 'button.won' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="sendLost()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'sent')">
                        {{ 'button.lost' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="download()" *ngIf="quoteDTO.id != null">
                        {{ 'button.download' | translate }}</button>
                    <button mat-raised-button color="secondary" (click)="saveQuoteVersion()"
                        *ngIf="quoteDTO.idStatus === 3 && quoteDTO.versionControl">
                        {{ 'button.version' | translate }}</button>
                    <button mat-raised-button color="secondary" (click)="cancel()">
                        {{ 'button.cancel' | translate }}</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab #quoteTab label="{{ 'quoteEdit.version.title' | translate }}" *ngIf="quoteDTO.idVersionControl != null">
        <form>
            <mat-card class="card">
                <mat-card-content>
                    <mat-list>
                        <mat-list-item *ngFor="let v of quoteDTOList; let indexx = index">
                            <div fxFlex="100">
                                <div fxFlex="50">
                                    v{{ v.numVersion }}
                                </div>
                                <div fxFlex="50">
                                    <div fxFlex="50" style="text-align: right;">
                                        <button mat-raised-button color="secondary" (click)="editVersion(v?.id)"
                                            *ngIf="quoteDTO.id != null">
                                            {{ 'button.edit' | translate }}</button>
                                    </div>
                                    <div fxFlex="50" style="text-align: right;">
                                        <button mat-raised-button color="secondary" (click)="downloadVersion(v)"
                                            *ngIf="quoteDTO.id != null">
                                            {{ 'button.download' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </form>
    </mat-tab>
</mat-tab-group>