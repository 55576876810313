import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../service/translation.service';



@Pipe({
  name: 'fixDate'
})
export class FixDate implements PipeTransform {

  constructor(private translateService: TranslationService) {

  }

  transform(value: Date | string | any): any {

    if (value == null) {
      return value;
    }

    if (value instanceof Date && isNaN(value.getTime())) {
      return null;
    }

    moment.locale(this.translateService.currentLang);

    return moment(value as Date).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();
  }

}
