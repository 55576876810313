export class TestThermal{

    id: number; 
    type: number;
    amount : number;
    setPoint: number;
    charge: string;
    duration: number;
    durationString: string;
    robeId:number;
    criteria: string;
    numberTrials: number;
    idEquipment:number;
    cost: number;
    price: number;
   }