import { GenericFilter } from "./genericClass";

export class User {
    id: number;

    username: string;
    fullName: string;

    email: string;
    location: string;
    roleId: number;
    officeId: number;
    signId: number;
    idOffice: number;
    idSign: number;
    roleTranslation: String;

    admin: boolean;
    unreadNotifications: number;
}

export class UserFilter extends GenericFilter{
    id: number;

    username: string;
    fullName: string;

    email: string;
    location: string;
    roleId: number;

}

export enum RoleUserEnum {
    CONSULTANCY = 1,
    COMMERCIAL = 2,
    ADMIN = 3
}