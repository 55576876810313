import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TestTypeThermalEquipmentConfig } from '../model/TestTypeThermalEquipmentConfig';

@Injectable()
export class TestTypeThermalEquipmentConfigService {

    baseUrl = `${environment.quotit}/testTypeThermalEquipmentConfigController`;

    currentLang: string;

    private courseSubject = new BehaviorSubject<TestTypeThermalEquipmentConfig>(new TestTypeThermalEquipmentConfig());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.courseSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }


    findByEquipmentId(id: number): Observable<any> {
        const url = `${this.baseUrl}/equipment/${id}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findByEquipmentIdTestId(idEq: number, idTest: number): Observable<any> {
        const url = `${this.baseUrl}/equipment/${idEq}/test/${idTest}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    
  }