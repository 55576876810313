import { AuthInterceptor } from './authInterceptor';
import { CommonModule } from '@angular/common';
import { HttpXsrfTokenExtractor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IsLoggedInterceptor } from './isLoggedInterceptor';
import { LoginService } from '../service/login.service';
import { NgModule } from '@angular/core';
import { SnackBarService } from '../service/snackBar.service';
import { XSRFInterceptor } from './xsrfInterceptor';

@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [LoginService, SnackBarService] },
    { provide: HTTP_INTERCEPTORS, useClass: XSRFInterceptor, multi: true, deps: [HttpXsrfTokenExtractor] },
    IsLoggedInterceptor,
  ],
  imports: [
    CommonModule
  ],
  exports: []
})
export class InterceptorsModule { }
