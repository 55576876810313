import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TestTypeThermalEquipmentConfig } from 'src/app/model/TestTypeThermalEquipmentConfig';
import { EquipmentThermal } from 'src/app/model/equipmentThermal';
import { TestThermal } from 'src/app/model/testThermal';
import { TestTypeThermalEquipmentConfigService } from 'src/app/service/testTypeThermalEquipmentConfig.service';
import { TestTypeThermal } from 'src/app/model/testTypeThermal';
import { TestTypeService } from 'src/app/service/testType.service';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TestThermalDurationService } from '../../../service/test-thermal-duration.service';
import { TestThermalDuration } from 'src/app/model/testThermalDuration';

export interface DialogDataTestThermal {
  equipmentThermal: EquipmentThermal;
  testThermal: TestThermal;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-dialog-equipment-thermal',
  templateUrl: './quote-dialog-test-thermal.html'
})
export class QuoteDialogTestThermal implements OnInit {
  selectedTestType: number;
  tituloDialog: string;
  idTest: number;
  testEquipemtConfig: TestTypeThermalEquipmentConfig[] = [];
  testTypes: TestTypeThermal[] = [];
  showOptions: boolean;
  testsByEquipment: TestTypeThermalEquipmentConfig[] = [];
  testThermalDuration: TestThermalDuration[] = [];
  showDuration: boolean;

  constructor(
    private testTypeThermalEquipmentConfigService: TestTypeThermalEquipmentConfigService,
    private testTypeService: TestTypeService,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private testThemalDurationService: TestThermalDurationService,
    private dialogRef: MatDialogRef<QuoteDialogTestThermal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTestThermal
  ) {
    if (this.data) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.edit', { name: data.testThermal }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.create') as string;
    }
  }

  ngOnInit(): void {

    this.testTypeThermalEquipmentConfigService.findByEquipmentId(this.data.equipmentThermal.type).subscribe(
      (res: TestTypeThermalEquipmentConfig[]) => {
        this.testEquipemtConfig = res.filter(config => config.idTestType != 1);
      }
    );

    this.testTypeService.findAllThermal().subscribe((res: TestTypeThermal[]) => {
      this.testTypes = res;
    });

    this.testThemalDurationService.findAll().subscribe((res: TestThermalDuration[]) => {
      this.testThermalDuration = res;
    });

    if (this.data.testThermal != null && this.data.testThermal.type !== null && this.data.testThermal.type !== 0) {
      this.showOptions = true;
    }

    if (this.data.testThermal.type == 11 || this.data.testThermal.type == 12 || this.data.testThermal.type == 9) {
      this.showDuration = false;
    } else {
      this.showDuration = true;
    }
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.testThermal?.type == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.test.name.error') as string);
    }
    if (this.data.testThermal?.criteria == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.test.criteria.error') as string);
    }
    if (!this.data.testThermal?.numberTrials) {
      errs.push(this.translate.instant('quoteEdit.dialog.test.numberTrials.error') as string);
    }
    if (!this.data.testThermal?.amount) {
      errs.push(this.translate.instant('quoteEdit.dialog.test.amount.error') as string);
    }
    if(!this.showDuration) {
      if (!this.data.testThermal?.durationString) {
        errs.push(this.translate.instant('quoteEdit.dialog.test.durationString.error') as string);
      }
    }
    if (errs.length === 0) {
      this.dialogRef.close(this.data.testThermal);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTestName(idType: number): string {
    let testName = '';

    this.testTypes.forEach(element => {
      if (element.id === idType) {
        testName = 'testType.'.concat(element.translate);
      }
    });

    return testName;
  }

  onTypeChange(event: MatSelectChange): void {
    let idType: number = event.value;
    this.data.testThermal.amount = this.testEquipemtConfig.find(config => config.idTestType === idType).probes;
    if (idType == 11 || idType == 12 || idType == 9) {
      this.showDuration = false;
    } else {
      this.showDuration = true;
    }
  }

}

