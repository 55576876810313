import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConsultancyCourse } from 'src/app/model/consultancyCourse';
import { Course, CourseFilter, CourseModality } from 'src/app/model/course';
import { CourseDiscount } from 'src/app/model/courseDiscount';
import { CourseService } from 'src/app/service/course.service';
import { CourseDiscountService } from 'src/app/service/courseDiscount.service';
import { CourseModalityService } from 'src/app/service/courseModality.service';

export interface TrainingEditDialogData {
  course: ConsultancyCourse;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-training',
  templateUrl: './quote-edit-dialog-training.component.html',
})
export class TrainingEditDialogComponent {
  tituloDialog: string;
  courses: Course[] = []; 
  coursesFiltered: Course[] = []; 

  courseType: Course = new Course();
  courseDiscounts: CourseDiscount[] = [];
  modalities: CourseModality[];
  searchText: string = '';

  constructor(
    private courseService: CourseService,
    private courseDiscountService: CourseDiscountService,
    private courseModalityService: CourseModalityService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<TrainingEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TrainingEditDialogData
  ) {}

  ngOnInit() {
    this.courseService.findAll().subscribe((res: Course[]) => {
      this.courses = res;
      this.coursesFiltered = this.courses.slice();
      this.courses.sort((a, b) => (a.name < b.name ? -1 : +(a.name > b.name)));

      if (this.data.isEdit) {
        this.courseType = this.courses.find((c) => c.id === this.data.course.idType);
      }
    });

    this.courseModalityService.findAll().subscribe((res: CourseModality[]) => {
      this.modalities = res;
    });

    if (this.data.isEdit) {
      this.courseType = this.courses.find((c) => c.id === this.data.course.idType);
    } else {
      this.courseType = new Course();
    }
    this.courseDiscountService.findAll().subscribe((res: CourseDiscount[]) => {
      this.courseDiscounts = res;
    });
  }

 
filterCourses() {
  if (!this.searchText) {
    this.coursesFiltered = this.courses.slice();
    return;
  }

  this.coursesFiltered = this.courses.filter((course) =>
    course.name.toLowerCase().includes(this.searchText.toLowerCase())
  );

  if (this.coursesFiltered.length === 1) {
    this.courseType = this.coursesFiltered[0];
  } else {
    this.courseType = null;
  }
}
  onOkClick(): void {
    const errs: string[] = [];

    this.data.course.idType = this.courseType?.id;
    if (this.data.course.idType == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.course.students == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.course);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  calculatePrice(): void {
    if (this.data.course.idModality == 2 || this.data.course.idModality == 1) {
      this.data.course.idType = this.courseType?.id;
      let sub = 0;
      if (this.courseType != null) {
        this.courseDiscounts.forEach((discount) => {
          if (
            this.data.course.students >= discount.min &&
            this.data.course.students <= discount.max
          ) {
            sub =
              (this.courseType.price * (discount.percentaje / 100)) * this.data.course.students;
          }
        });

        this.data.course.price = this.courseType.price * this.data.course.students - sub;
      }
    } else if (this.data.course.idModality == 3) {
      this.data.course.price = this.courseType?.price * this.data.course.students;
    } else {
      this.data.course.price = 0;
    }
  }
}