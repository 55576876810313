import { CompresedGas } from "./compresedGas";
import { ConsultancyProfile } from "./consultancyProfile";
import { Equipment } from "./equipment";
import { EquipmentThermal } from "./equipmentThermal";
import { Room } from "./room";

export class Area {
    id: number;
    name: string;
    idworkcenter: number;
    consultoscoperyScope: string;
    consultancyProfiles: ConsultancyProfile[] = [];
    
    equipments: Equipment[] = [];
    rooms: Room[]= [];
    compressedGases: CompresedGas[]= [];
    scope: string;
    
    totalConsultancyRoom: number;
    totalConsultancyCostRoom: number;
    totalConsultancyTimeRoom: number;

    equpmentThermal: EquipmentThermal[]=[];
}