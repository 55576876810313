import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { User } from 'src/app/model/user';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {

  form: FormGroup;
  user: User
  roles: [];
  offices: [];

  constructor(
    fb: FormBuilder) {

    this.user = new User();
    this.form = fb.group({
      username: [this.user.username],
      fullName: [this.user.fullName],
      location: [this.user.location],
      roleId: [this.user.roleId],
      officeId: [this.user.officeId]
    });
  }

  ngOnInit(): void {

  }

  saveUser(): void {

  }

  cancel(): void {

  }
}
