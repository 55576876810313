import * as moment from 'moment';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericClass } from 'src/app/model/genericClass';
import { Subject } from 'rxjs';
import { QuoteFilter } from 'src/app/model/quote';
import { CustomDatepickerHeaderComponent } from '../shared/datepicker-custom-header.component';
import { ClientService } from '../../service/client.service';
import { Client } from '../../model/client';
import { QuoteType } from '../../model/quote';
import { QuoteTypeService } from '../../service/quoteType.service';
import { ContactService } from 'src/app/service/contactService';
import { Contact } from '../../model/contact';
import { CommercialService } from '../../service/commercial.service';
import { commercial } from '../../model/commercial';
import { Constants } from '../../utils/constants';


@Component({
  selector: 'app-quote-list-filter',
  templateUrl: './quote-list-filter.component.html'
})
export class QuoteListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<QuoteFilter>();

  filter: QuoteFilter;

  statuses: GenericClass[];
  processes: GenericClass[];
  clients: Client[];
  expandedFilter = true;
  quoteTypes: QuoteType[];
  contacts: Contact[];
  clientsFiltered: Client[];
  contactsFiltered: Contact[];
  commercials: commercial[];
  status: any[] = [];

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private quoteTypeService: QuoteTypeService,
    private contactService: ContactService,
    private commercialService: CommercialService) {
    this.cleanFilter();


  }

  ngOnInit(): void {
    this.statuses = [{'id': Constants.QUOTE_ID_STATUS_WON, 'name': Constants.QUOTE_STATUS_WON}, {'id': Constants.QUOTE_ID_STATUS_LOST, 'name': Constants.QUOTE_STATUS_LOST}]
    this.clientService.findAll().subscribe((res: Client[]) => {
      this.clients = res;
      this.clientsFiltered = this.clients?.slice();
    });

    this.quoteTypeService.findAll().subscribe((res: QuoteType[]) => {
      this.quoteTypes = res;
    });

    this.contactService.findAllClientContact().subscribe((res: Contact[]) => {
      this.contacts = res;
      this.contactsFiltered = this.contacts?.slice();
      this.contactsFiltered?.sort((a,b) => a.fullName < b.fullName ? -1 : +(a.fullName > b.fullName));
    });

    this.commercialService.findAllCommercial().subscribe((res: commercial[]) => {
      this.commercials = res;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new QuoteFilter();
  }

  saveDate(field: string, event: any): void {

    let value: Date | string = event.target.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.filter[field] = date;
    } else {
      this.filter[field] = null;
    }

  }



}
