import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService {

    public isLoggedIn: boolean;

    private loggedInSubject = new BehaviorSubject<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public isLoggedIn$ = this.loggedInSubject.asObservable();

    private AUTH_TOKEN_KEY = 'authToken';

    constructor(
        private http: HttpClient,
        private router: Router,
        private dialogRef: MatDialog,
        private msalService: MsalService,
        private userService: UserService,
        private ngxCookieService: NgxCookieService) {
        this.isLoggedIn$.subscribe(res => this.isLoggedIn = res);
    }

    login(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.msalService.loginPopup().subscribe(response => {
                localStorage.setItem(this.AUTH_TOKEN_KEY, response.accessToken);
                this.msalService.instance.setActiveAccount(response.account);

                resolve();
            });
        });
    }

    goHome(): void {
        this.userService.profile().subscribe((res: User) => {
            this.ngxCookieService.set('User', res.username, 7);
            this.userService.setProfileSidebar(res);
            this.loggedInSubject.next(true);
            void this.router.navigate(['/quotes']);
        });
    }

    logout(): void {
        const url = environment.quotit + '/logout';
        this.http.post(url, {}).subscribe(() => this.completeLogout(), () => this.completeLogout());
        localStorage.clear();
    }

    clearSession(): void {
        this.loggedInSubject.next(false);
        void this.router.navigate(['/login?expired=true']);
        localStorage.clear();
    }
    
    getToken(): string {
        return localStorage.getItem(this.AUTH_TOKEN_KEY);
    }

    private completeLogout(): void {
        this.dialogRef.closeAll();
        this.clearSession();
        void this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
    }

}
