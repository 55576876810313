import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

import { Client, ClientFilter as ClientFilter } from 'src/app/model/client';
import { ClientListFilterComponent } from './client-list-filter.component';

import { Location, ViewportScroller } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ClientDataSource } from 'src/app/model/clientDataSource';
import { User } from 'src/app/model/user';
import { ClientService } from 'src/app/service/client.service';
import { UserService } from 'src/app/service/user.service';

export interface DialogDataCourseListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html'
})
export class ClientListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('filterComponent', { static: true }) filterComponent: ClientListFilterComponent;
  currentUser: User;
  dataSource: ClientDataSource;
  filter: ClientFilter = new ClientFilter();
  hasFiltered = false;
  displayedColumns = ['name','address', 'postalCode','city','state','country','edit', 'delete'];
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private clientService: ClientService,
    private location: Location,
    private translate: TranslateService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCourseListConfig,
    public dialogRef: MatDialogRef<ClientListComponent>
  ) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('protocolList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.data.isList) {
      this.displayedColumns.push('download', 'edit', 'delete');
    } else {
      this.filterComponent.isList = this.data.isList;
    }
    this.loadClientList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadClientList(): void {
    this.dataSource = new ClientDataSource(this.clientService);

    this.filter.sortBy = this.sort.active || 'name';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;

    if (this.hasFiltered) {
      this.dataSource.loadProtocols(this.filter);
    }
  }

  new(): void {
    void this.router.navigateByUrl('/client?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/client?id=${id}`);
  }

  doFilter(event: ClientFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadClientList();
  }

  canDelete(client: Client): boolean {
    return true;
  }

  backPreviousPage(): void {
    this.location.back();
  }
}
