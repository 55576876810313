import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller, Location } from '@angular/common';
import { UserDataSource } from 'src/app/model/UserDataSource';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, Scroll } from '@angular/router';
import { Subject, merge,  } from 'rxjs';
import { filter, takeUntil, tap } from "rxjs/operators";
import { UserService } from 'src/app/service/user.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UserFilter } from 'src/app/model/user';

export interface DialogDataUserListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: UserDataSource;
  displayedColumns = ['name', 'role', 'edit', 'delete'];
  hasFiltered = false;
  filter: UserFilter = new UserFilter();
  private destroy$ = new Subject<void>();
  
  constructor(
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUserListConfig,
  ) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  ngOnInit(): void {
    if (this.data.isList) {
      this.displayedColumns.push('download', 'edit', 'delete');
    } else {
      //this.filterComponent.isList = this.data.isList;
    }
    this.loadCourseList();
  }

  new (): void {

  }

  cancel(): void {
    this.location.back();
  }

  loadCourseList(): void {
    this.dataSource = new UserDataSource(this.userService);

    this.filter.sortBy = this.sort.active || 'fullName';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 5;

    if (this.hasFiltered) {
      this.dataSource.loadProtocols(this.filter);
    }
   // this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  doFilter(event: UserFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadCourseList();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadCourseList())).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
