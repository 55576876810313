<mat-card>
    <div fxLayout="row" class="flexwrap">

        <div fxFlex="30" fxFlex.lt-md="100%" *ngFor="let result of results">
            <div fxLayout="column" fxFlex.lt-md="100%">
                <mat-card-content>
                    <div class="economic">
                        <div fxLayout="row" class="flexwrap">

                            <div fxFlex="100" fxFlex.lt-md="100%">
                                <div fxLayout="column" fxFlex.lt-md="100%">
                                    <div fxLayout="row" class="flexwrap">
                                        <b>{{result.name}}</b>
                                    </div>
                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                        Venta calibraciones: {{result.sumPriceCalibrations}}€
                                    </div>
                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                        Coste calibraciones: {{result.sumCostCalibrations}}€
                                    </div>
                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                        Tiempo total calibraciones: {{result.sumTimeCalibrations}}h
                                    </div>
                                    <div *ngFor="let area of result.areas" fxFlex="100" class="flexwrap"
                                        fxFlex.lt-md="100%">
                                        <br>
                                        {{area.name}}: {{area.totalCostTestPerArea}}€
                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                            Tiempo total: {{area.totalTimePerArea}}m {{getHours(area.totalTimePerArea)}}h 
                                        </div>
                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                            Jornadas: {{area.totalDays}}D
                                        </div>
                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                            Coste producción: {{area.costProduction}}€
                                        </div>
                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                            Venta consultoria: {{area.totalSaleConsultor}}€
                                        </div>
                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                            Coste consultoria: {{area.totalCostConsultor}}€
                                        </div>
                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                            Tiempo total consultoria: {{area.totalTimeConsultor}}h
                                        </div>
                                    </div>
                                    <br>

                                    <div fxLayout="row" class="flexwrap" *ngIf="isConsultancy">
                                        {{ 'Formación' | translate }}: {{ this.totalCourses }}€
                                    </div>
                                    <div fxLayout="row" class="flexwrap" *ngIf="isConsultancy">
                                        {{ 'Auditorias' | translate }}: {{ result.sumAudits }}€
                                    </div>
                                    <div fxLayout="row" class="flexwrap" *ngIf="isThermal">
                                        {{ 'Venta térmicos' | translate }}: {{ this.totalSaleThermal }}€
                                    </div>
                                    <div fxLayout="row" class="flexwrap" *ngIf="isThermal">
                                        {{ 'Coste térmicos' | translate }}: {{ this.totalCostThermal }}€
                                    </div>
                                    <div fxLayout="row" class="flexwrap">
                                        {{ 'Dietas' | translate }}: {{ result.sumDiets }}€
                                    </div>
                                    <br>
                                    
                                    <div *ngFor="let audit of result.auditDTOs" fxFlex="100" class="flexwrap"
                                        fxFlex.lt-md="100%">
                                        <br>
                                        {{ showAuditName(audit.auditType) | translate }}
                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                            Precio: {{audit.price}}€
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>

                </mat-card-content>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex.lt-md="100%">
        <mat-card-content>
            <div class="economic">
                <div fxLayout="row" class="flexwrap">
                    <mat-card>
                        <div fxLayout="row" class="flexwrap">
                            {{ 'Coste total ' | translate }}: {{ quote.totalCost }}€
                        </div>
                        <div fxLayout="row" class="flexwrap">
                            <b>{{ 'Precio de venta total' | translate }}: {{ quote.totalAmount }}€</b>
                        </div>
                        <div fxLayout="row" class="flexwrap">
                            {{ 'Procentaje de beneficio' | translate }}: {{ gainsPercentaje }}%
                        </div>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
    </div>
</mat-card>