import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

import { CompresedGas } from 'src/app/model/compresedGas';
import { ConsultancyType } from 'src/app/model/ConsultancyType';
import { ProviderQuote } from 'src/app/model/providerQuote';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { Test, TestType } from 'src/app/model/test';
import { TestTypeEquipmentConfig } from 'src/app/model/testTypeEquipmentConfig';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TestTypeService } from 'src/app/service/testType.service';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { Constants } from 'src/app/utils/constants';

export interface DialogDataCompressedGas {
  gas: CompresedGas;
  isEdit: boolean;
  showAir: boolean;
  showQualis: boolean;
}

@Component({
  selector: 'app-quote-edit-gas',
  templateUrl: './quote-edit-gas.component.html'
})
export class QuoteEditGasComponent implements OnInit {

  tituloDialog: string;
  testsByGas: TestTypeEquipmentConfig[] = [];
  testTypes: TestType[] = [];
  countEssayCriteriasRest: TestTypeEquipmentConfig[] = [];
  countEssayCriteriasWorking: TestTypeEquipmentConfig[] = [];
  consultancyTypes: ConsultancyType[];

  constructor(
    public snackBarService: SnackBarService,
    private consultancyTypeService: ConsultancyTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private testTypeService: TestTypeService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditGasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCompressedGas
  ) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.gas.title.edit', { name: data.gas.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.gas.title.create') as string;
    }
    if (data.gas.provider != null) {
      data.gas.outsourced = true;
    }
  }

  ngOnInit(): void {
    this.testTypeEquipmentConfigService.findByGas(1).subscribe((res: TestTypeEquipmentConfig[]) => {
      this.testsByGas = res;

      this.testTypeService.findAll().subscribe((res: TestType[]) => {
        this.testTypes = res;
        this.testsByGas.forEach(test => {
          this.testTypes.forEach(type => {
            if (test.idTestType === type.id) {
              test.translation = this.getEssayName(type);
            }
            // Marcar check del ensayo cuando estamos editando

            this.data.gas.tests.forEach(testSave => {
              if (testSave.idType === test.idTestType) {
                test.isChecked = true;
              }
            });

          });
        });

      });
      this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdGas(Constants.ID_GAS, Constants.ID_TYPE_REST).subscribe((res: any[]) => {
        this.countEssayCriteriasRest = res;
        if (this.data.isEdit) {
          if (this.data?.gas?.countTestRest) {
            this.data.gas.idCountEssayCriteria = this.countEssayCriteriasRest.find(e => e.reference === this.data.gas.countTestRest.reference).id
          }
        }
      });
  
      this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdGas(Constants.ID_GAS, Constants.ID_TYPE_WORKING).subscribe((res: any[]) => {
        this.countEssayCriteriasWorking = res;
        if (this.data.isEdit) {
          if (this.data?.gas?.countTestWorking) {
            this.data.gas.idCountEssayCriteriaWorking = this.countEssayCriteriasWorking.find(e => e.reference === this.data.gas.countTestWorking.reference).id
          }
        }
      });
      this.testsByGas =  this.testsByGas.filter((item) => item.idTestType !== Constants.ID_TYPE_WORKING);
      this.testsByGas =  this.testsByGas.filter((item) => item.idTestType !== Constants.ID_TYPE_REST);

      this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => {
        this.consultancyTypes = types;
        this.consultancyTypes.forEach(type => {
          if (this.data.isEdit) {
            this.data?.gas?.qualifications.forEach(quali => {
              if (quali.idType === type.id) {
                type.isChecked = true;
              }
            });
          }
        })
      });
    });
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.gas.name == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.name.error') as string);
    }
    if (this.data.gas.location == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.location.error') as string);
    }
    if(this.data.gas.outsourced){
      if (this.data.gas.provider.cost ==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste.error') as string);
      }
      if (this.data.gas.provider?.cost <=0) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste0.error') as string);
      }
      if (this.data.gas.provider.id==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.error') as string);
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.gas);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  hasOutsourcer(): boolean {
    return this.data.gas.outsourced;
  }

  setOutsourced(completed: boolean) {
    this.data.gas.outsourced = completed;
    if(completed){
      this.data.gas.provider = new ProviderQuote();
    }else{
      this.data.gas.provider = null;
    }
  }

  onCheckTest(event: any): void {

    if (event.checked) {
      let test = new Test();
      test.idEquipment = this.data.gas.id;
      test.idType = event.idTestType;
      test.idTestTypeEquipmentConfig = event.id;
      test.time= event.time
      this.data.gas.tests.push(test);
    } else {
      this.data.gas.tests = this.data.gas.tests.filter(test => test.idType != event.idTestType);
    }
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }

  showCountRest(): boolean {
    return true;
  }

  showCountWorking(): boolean {
    return false;
  }

  onCountCriteriaChangeRest(event: MatSelectChange): void {
    let test;
    if (event?.source?.value != null && this.countEssayCriteriasRest != null) {
      for (const item of this.countEssayCriteriasRest) {
        if (item.id === event.source.value) {
          test = new Test();
          test.reference = item.reference;
          test.idRoom = this.data.gas.id;
          test.idType = item.idTestType;
          test.idTestTypeEquipmentConfig = item.id;
          test.time= item.time
          break;
        }
      }
      this.data.gas.countTestRest = test;
    }
  }

  onCountCriteriaChangeWorking(event: MatSelectChange): void {
    let test;
    if (event?.source?.value != null && this.countEssayCriteriasWorking != null) {
      for (const item of this.countEssayCriteriasWorking) {
        if (item.id === event.source.value) {
          test = new Test();
          test.reference = item.reference;
          test.idRoom = this.data.gas.id;
          test.idType = item.idTestType;
          test.idTestTypeEquipmentConfig = item.id;
          test.time= item.time
          break;
        }
      }
      this.data.gas.countTestWorking = test;
    }
  }

  onCheckQualification(event: any): void {

    if (event.checked) {
      let test = new StandarConsultancy();
      test.idGas = this.data.gas.id;
      test.idType = event.id;
      this.data.gas.qualifications.push(test);
    } else {
      this.data.gas.qualifications = this.data.gas.qualifications.filter(test => test.idType != event.id);
    }
  }
}
