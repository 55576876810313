import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentThermal } from 'src/app/model/equipmentThermal';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EquipmentTypeService } from 'src/app/service/equipmentType.service';
import { TestThermal } from 'src/app/model/testThermal';
import { QuoteDialogTestThermal } from '../quote-dialog-test-thermal/quote-dialog-test-thermal';
import { EquipmentType } from 'src/app/model/equipment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject, takeUntil } from 'rxjs';
import { Workcenter } from 'src/app/model/workCenter';
import * as _ from 'lodash-es';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TestTypeThermalEquipmentConfig } from 'src/app/model/TestTypeThermalEquipmentConfig';
import { ThermalProbe } from 'src/app/model/thermalProbe';
import { QuoteDialogProbeThermal } from '../quote-dialog-probe-thermal/quote-dialog-probe-thermal';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TestTypeService } from 'src/app/service/testType.service';
import { TestTypeThermal } from 'src/app/model/testTypeThermal';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Constants } from 'src/app/utils/constants';
import { ConsultancyType } from 'src/app/model/ConsultancyType';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { TestThermalDuration } from 'src/app/model/testThermalDuration';
import { TestThermalDurationService } from 'src/app/service/test-thermal-duration.service';

export interface DialogEquipmentThermalData {
  equipmentThermal: EquipmentThermal;
  isEdit: boolean;
  showQualis: boolean;
}

@Component({
  selector: 'app-quote-dialog-equipment-thermal',
  templateUrl: './quote-dialog-equipment-thermal.html',
})
export class QuoteDialogEquipmentThermal implements OnInit {

  tituloDialog: string;
  equipmentTypes: EquipmentType[];
  showProbes: string;
  testTypes: TestTypeThermalEquipmentConfig[]=[];
  testThermal: TestThermal[] = [];
  workcenters: Workcenter[] = [];
  testTypeNames: TestTypeThermal[] = [];
  indexEq: number;
  consultancyTypes: ConsultancyType[];
  testThermalDuration: TestThermalDuration[] = [];

  private destroy$ = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    private equipmentService: EquipmentTypeService,
    private consultancyTypeService: ConsultancyTypeService,
    public snackBarService: SnackBarService,
    private testTypeService: TestTypeService,
    private testThemalDurationService: TestThermalDurationService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteDialogEquipmentThermal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogEquipmentThermalData
  ) {
    if (this.data.isEdit) {
      let serial = this.data.equipmentThermal.serialNumber != null ? this.data.equipmentThermal.serialNumber : data.equipmentThermal.identificator;
      this.tituloDialog = this.translate.instant(
        'quoteEdit.dialog.equipment.thermal.title.edit',
        {
          model: data.equipmentThermal.model,
          serial,
        }
      ) as string;
    } else {
      this.tituloDialog = this.translate.instant(
        'quoteEdit.dialog.equipment.thermal.title.create'
      ) as string;
    }
  }

  ngOnInit(): void {
    if (this.data.equipmentThermal != null && this.data.equipmentThermal.id != null) {
      if (this.data.equipmentThermal.calibrationInstrumental === true) {
        this.showProbes = '1';
      } else if (this.data.equipmentThermal.calibrationInstrumental === false) {
        this.showProbes = '2';
      } else {
        this.showProbes = '1';
      }
    } else if (this.data.equipmentThermal?.id === null && this.data.equipmentThermal.calibrationInstrumental === false) {
      this.showProbes = '2';
    } else {
      this.showProbes = '1';
    }
    this.equipmentService.findAllByIdAplication(2).subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;
    });
    this.testTypeService.findAllThermal().subscribe((res: TestTypeThermal[]) => {
      this.testTypeNames = res;
    });
    this.testThemalDurationService.findAll().subscribe((res: TestThermalDuration[]) => {
      this.testThermalDuration = res;
    });
    this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => {
      this.consultancyTypes = types;
      this.consultancyTypes.forEach(type => {
        if (this.data.isEdit) {
          this.data?.equipmentThermal?.qualifications.forEach(quali => {
            if (quali.idType === type.id) {
              type.isChecked = true;
            }
          });
        }
      })
    });
    if (!this.data.isEdit) {
      let protocol = new TestThermal();
      protocol.type = 1;
      this.data.equipmentThermal.test.push(protocol);
    }

  }

  onCheckQualification(event: any): void {

    if (event.checked) {
      let test = new StandarConsultancy();
      test.idEquipmentThermal = this.data.equipmentThermal.id;
      test.idType = event.id;
      this.data.equipmentThermal.qualifications.push(test);
    } else {
      this.data.equipmentThermal.qualifications = this.data.equipmentThermal.qualifications.filter(test => test.idType != event.id);
    }
  }

  getModifiedTranslation(testThermal: TestThermal): string {
    if (testThermal.type === Constants.TEST_THERMAL_TYPE_PROTOCOL) {
      return 'quoteEdit.dialog.equipment.test.thermal.protocol';
    }
    if (testThermal.type === Constants.TEST_THERMAL_TYPE_VACUUM) {
      if (testThermal.amount === 1) {
        return 'quoteEdit.dialog.equipment.test.thermal.vacuum.singular';
      } else {
        return 'quoteEdit.dialog.equipment.test.thermal.vacuum.plural';
      }
    } else {
      if (testThermal.amount === 1) {
        return 'quoteEdit.dialog.equipment.test.thermal.singular';
      } else {
        return 'quoteEdit.dialog.equipment.test.thermal.plural';
      }
    }
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.equipmentThermal?.type == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.equipmentThermal?.maker == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.maker.error') as string);
    }
    if (this.data.equipmentThermal?.model == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.mode.error') as string);
    }
    if (this.data.equipmentThermal?.location == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.ubication.error') as string);
    }

    if (!this.data.equipmentThermal.test || this.data.equipmentThermal.test?.length === 0) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.test.error') as string);
    }

    if (this.showProbes === '1') {
      this.data.equipmentThermal.calibrationInstrumental = true;
    } else if (this.showProbes === '2') {
      this.data.equipmentThermal.calibrationInstrumental = false;
    } else {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.instrumental.error') as string );
    }

    if (this.showProbes === '1' && (!this.data.equipmentThermal.thermalProbe || this.data.equipmentThermal.thermalProbe?.length === 0)) {
      errs.push(this.translate.instant('quoteEdit.dialog.equipmentThermal.probe.error') as string );
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.equipmentThermal);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // TestThermal pop up
  openDialogTestThermal() {

    const dialogRef = this.dialog.open(QuoteDialogTestThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        testThermal: new TestThermal(),
        isEdit: false,
        equipmentThermal: this.data.equipmentThermal
      }
    });

    dialogRef.afterClosed().subscribe((newTestThermal: TestThermal) => {
      if (newTestThermal != null) {
        this.data.equipmentThermal.test.push(newTestThermal);
      }
    });
  }

  //ProbeThermal pop-up
  openDialogProbeThermal() {
    const dialogRef = this.dialog.open(QuoteDialogProbeThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      minHeight: '25vh',
      data: {
        thermalProbe: new ThermalProbe(),
        isEdit: false,
      },
    });
    // Muestra lo que escribes dentro del pop-up una vez cerrado
    dialogRef.afterClosed().subscribe((modifiedThermalProbe: ThermalProbe) => {
      if (modifiedThermalProbe) {
        this.data.equipmentThermal.thermalProbe.push(modifiedThermalProbe);
      }
    });
  }

  //ProbeThermal pop-up
  editProbeThermal(indexProbe: number) {
    const probe = this.data.equipmentThermal.thermalProbe[indexProbe];
    const dialogRef = this.dialog.open(QuoteDialogProbeThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      minHeight: '25vh',
      data: {
        thermalProbe: _.cloneDeep(probe),
        isEdit: true,
      },
    });
    // Muestra lo que escribes dentro del pop-up una vez cerrado
    dialogRef.afterClosed().subscribe((modifiedThermalProbe: ThermalProbe) => {
      if (modifiedThermalProbe) {
        this.data.equipmentThermal.thermalProbe[indexProbe] = modifiedThermalProbe;
      }
    });
  }

  deleteProbeThermal(indexProbe: number): void {
    const message = this.translate.instant('quoteEdit.dialog.probeThermal.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexProbe > -1) {
          this.data.equipmentThermal.thermalProbe.splice(indexProbe, 1);
        }
      }
    });
  }

  editTestThermal(indexTest: number) {
    const test = this.data.equipmentThermal.test[indexTest];
    const dialogRef = this.dialog.open(QuoteDialogTestThermal, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        testThermal: _.cloneDeep(test),
        isEdit: true,
        equipmentThermal: this.data.equipmentThermal
      },
    });

    dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(() => {
      dialogRef.componentInstance.tituloDialog = this.translate.instant('quoteEdit.dialog.equipment.test.edit.title');
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((modifiedTestThermal: TestThermal) => {
      if (modifiedTestThermal != null) {
        this.data.equipmentThermal.test[indexTest] = modifiedTestThermal;
      }
    });
  }

  deleteTestThermal(indexTest: number): void {
    const message = this.translate.instant('quoteEdit.dialog.testThermal.form.confirmDelete') as string;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexTest > -1) {
          this.data.equipmentThermal.test.splice(indexTest, 1);
        }
      }
    });
  }

  onCalibrationChange(event: MatButtonToggleGroup): void {
    if (event.value === '1') {
      this.showProbes = '1';
    } else{
      this.showProbes = '2';
      this.data.equipmentThermal.thermalProbe = [];
    }
  }

  showTestName(idType: number): string {
    let name = '';

    this.testTypeNames.forEach(e => {
      if (e.id === idType) {
        name = e.translate;
      }
    });
    return 'testType.'.concat(name);
  }

  getTestDuration(test: TestThermal) :string {
    if(test.duration != null ) {
      return this.translate.instant('duration.thermal.'.concat(this.testThermalDuration.find(d => d.id === test.duration).duration));
    } else {
      return test.durationString;
    }
     
  }
}
