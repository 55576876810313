import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ConsultancyType } from 'src/app/model/ConsultancyType';
import { ProviderQuote } from 'src/app/model/providerQuote';

import { Room } from 'src/app/model/room';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { Test, TestType } from 'src/app/model/test';
import { TestTypeEquipmentConfig } from 'src/app/model/testTypeEquipmentConfig';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { TestTypeService } from 'src/app/service/testType.service';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { Constants } from 'src/app/utils/constants';

export interface DialogDataRoom {
  room: Room;
  isEdit: boolean;
  showAir: boolean;
  showQualis: boolean;
}

@Component({
  selector: 'app-quote-edit-room-dialog',
  templateUrl: './quote-edit-room-dialog.component.html'
})
export class QuoteEditRoomDialogComponent implements OnInit {

  tituloDialog: string;
  testsByEquipment: TestTypeEquipmentConfig[] = [];
  testTypes: TestType[] = [];
  countEssayCriteriasRest: TestTypeEquipmentConfig[] = [];
  countEssayCriteriasWorking: TestTypeEquipmentConfig[] = [];
  consultancyTypes: ConsultancyType[];
  
  constructor(
    private consultancyTypeService: ConsultancyTypeService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    private testTypeService: TestTypeService,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditRoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRoom
  ) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.room.title.edit', { name: data.room.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.room.title.create') as string;
    }
    if (data.room.provider != null) {
      data.room.outsourced = true;
    }
  }
  ngOnInit(): void {
    this.data.room.idType = 1;
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });
    
    this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdRoom(Constants.ID_ROOM, Constants.ID_TYPE_REST).subscribe((res: any[]) => {
      this.countEssayCriteriasRest = res;
      if (this.data.isEdit) {
        if (this.data?.room?.countTestRest) {
          this.data.room.idCountEssayCriteria = this.countEssayCriteriasRest.find(e => e.reference === this.data.room.countTestRest.reference).id
        }
      }
    });

    this.testTypeEquipmentConfigService.findAllCatalogCountByIdTypeAndIdRoom(Constants.ID_ROOM, Constants.ID_TYPE_WORKING).subscribe((res: any[]) => {
      this.countEssayCriteriasWorking = res;
      if (this.data.isEdit) {
        if (this.data?.room?.countTestWorking) {
          this.data.room.idCountEssayCriteriaWorking = this.countEssayCriteriasWorking.find(e => e.reference === this.data.room.countTestWorking.reference).id
        }
      }
    });
      // Cargamos los ensayos
      //TODO: Cambiar idType a 1
      this.findByEquipment(1, this.data.isEdit);

      this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => {
        this.consultancyTypes = types;
        this.consultancyTypes.forEach(type => {
          if (this.data.isEdit) {
            this.data?.room?.qualifications.forEach(quali => {
              if (quali.idType === type.id) {
                type.isChecked = true;
              }
            });
          }
        })
      });

    }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.room.name == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.name.error') as string);
    }
    if (this.data.room.roomNumber == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.number.error') as string);
    }
    if (this.data.room.numberDiffusers == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.totalDiffusers.error') as string);
    }
    if (this.data.room.totalFilters == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.room.totalFilters.error') as string);
    }

    if(this.data.room.outsourced){
      if (this.data.room.provider.cost ==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste.error') as string);
      }
      if (this.data.room.provider?.cost <=0) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.coste0.error') as string);
      }
      if (this.data.room.provider.id==null) {
        errs.push(this.translate.instant('quoteEdit.dialog.equipment.provider.error') as string);
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.room);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkOutsourced(): boolean {
    return this.data.room.outsourced
  }

  onCheckTest(event: any): void {

    if (event.checked) {
      let test = new Test();
      test.idRoom = this.data.room.id;
      test.idType = event.idTestType;
      test.idTestTypeEquipmentConfig = event.id;
      test.time= event.time;
      this.data.room.tests.push(test);
    } else {
      this.data.room.tests = this.data.room.tests.filter(test => test.idType != event.idTestType);
    }
  }

  onCountCriteriaChangeRest(event: MatSelectChange): void {
    let test;
    if (event?.source?.value != null && this.countEssayCriteriasRest != null) {
      for (const item of this.countEssayCriteriasRest) {
        if (item.id === event.source.value) {
          test = new Test();
          test.reference = item.reference;
          test.idRoom = this.data.room.id;
          test.idType = item.idTestType;
          test.idTestTypeEquipmentConfig = item.id;
          test.time= item.time
          break;
        }
      }
      this.data.room.countTestRest = test;
    }
  }

  onCountCriteriaChangeWorking(event: MatSelectChange): void {
    let test;
    if (event?.source?.value != null && this.countEssayCriteriasWorking != null) {
      for (const item of this.countEssayCriteriasWorking) {
        if (item.id === event.source.value) {
          test = new Test();
          test.reference = item.reference;
          test.idRoom = this.data.room.id;
          test.idType = item.idTestType;
          test.idTestTypeEquipmentConfig = item.id;
          test.time= item.time
          break;
        }
      }
      this.data.room.countTestWorking = test;
    }
  }
  
  setOutsourced(completed: boolean) {
    this.data.room.outsourced = completed;
    if(completed){
      this.data.room.provider = new ProviderQuote();
    }else{
      this.data.room.provider = null;
    }
  }

  calculatePoints() {
    this.data.room.countPoints = this.calculateCountPoints();
    this.data.room.microPoints = 1;
  }

  onCheckQualification(event: any): void {

    if (event.checked) {
      let test = new StandarConsultancy();
      test.idRoom = this.data.room.id;
      test.idType = event.id;
      this.data.room.qualifications.push(test);
    } else {
      this.data.room.qualifications = this.data.room.qualifications.filter(test => test.idType != event.id);
    }
  }
  
  private findByEquipment(id: number, isEdit: boolean): void {
    this.testTypeEquipmentConfigService.findByRoom(id).subscribe((res: TestTypeEquipmentConfig[]) => {
      this.testsByEquipment = res;
      this.testsByEquipment =  res.filter((item) => item.idTestType !== Constants.ID_TYPE_REST);
      this.testsByEquipment =  this.testsByEquipment.filter((item) => item.idTestType !== Constants.ID_TYPE_WORKING);
      this.testsByEquipment.forEach(test => {
        this.testTypes.forEach(type => {
          if (test.idTestType === type.id) {
            test.translation = this.getEssayName(type);
          }
        });
        // Marcar check del ensayo cuando estamos editando
        if (isEdit) {
          this.data?.room?.tests.forEach(testSave => {
            if (testSave.idType === test.idTestType) {
              test.isChecked = true;
            }
          });
        }
      });
    });
  }

  private getEssayName(type: TestType): string {
    return "testType.".concat(type.translate);
  }

  private calculateCountPoints() : number {
    let points = 0;
    const surface = this.data.room.roomSurface;
    if(surface <= 2){
      return 1;
    } else if (surface <= 4) {
      return 2;
    }else if (surface <= 6) {
      return 3;
    } else if (surface <= 8) {
      return 4;
    } else if (surface <= 10) {
      return 5;
    } else if (surface <= 24) {
      return 6;
    } else if (surface <= 28) {
      return 7;
    }else if (surface <= 32) {
      return 8;
    } else if (surface <= 36) {
      return 9;
    } else if (surface <= 52) {
      return 10;
    } else if (surface <= 56) {
      return 11;
    } else if (surface <= 64) {
      return 12;
    } else if (surface <= 68) {
      return 13;
    } else if (surface <= 72) {
      return 14;
    } else if (surface <= 76) {
      return 15;
    } else if (surface <= 104) {
      return 16;
    } else if (surface <= 108) {
      return 17;
    } else if (surface <= 116) {
      return 18;
    } else if (surface <= 148) {
      return 19;
    } else if (surface <= 156) {
      return 20;
    } else if (surface <= 192) {
      return 21;
    } else if (surface <= 232) {
      return 22;
    } else if (surface <= 276) {
      return 23;
    } else if (surface <= 352) {
      return 24;
    } else if (surface <= 436) {
      return 25;
    } else if (surface <= 636) {
      return 26;
    } else if (surface <= 1000) {
      return 27;
    }
    return points;
  }

  showCountRest(): boolean {
    return true;
  }

  showCountWorking(): boolean {
    return true;
  }
}
