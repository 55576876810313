import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { PaginatorI18n } from 'src/app/utils/paginatorI18n';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomDatepickerHeaderComponent } from './datepicker-custom-header.component';
import { QuillModule } from 'ngx-quill'
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { DuplicateDialogComponent } from './duplicate-dialog/duplicate-dialog.component'
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        HeaderComponent, SidebarComponent, FooterComponent, CustomDatepickerHeaderComponent, DuplicateDialogComponent
    ],
    imports: [
        TranslateModule,
        RouterModule,
        FormsModule,
        /* Custom modules */
        AngularMaterialModule,

        /* Otros */
        FlexLayoutModule,
        MatButtonToggleModule,
        QuillModule.forRoot()
    ],
    providers: [
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
        }
    ],
    exports: [
        TranslateModule, HeaderComponent, SidebarComponent, FooterComponent, CustomDatepickerHeaderComponent,  QuillModule, 
        MatButtonToggleModule
    ]
})
export class SharedModule { }
