import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { QuoteEditComponent } from './quote-edit.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { QuoteDetailModule } from '../quote-detail/quote-detail.module';
import { QuoteTypeAirPipe } from './quote-edit-pipe/quote-type-air.pipe';
import { QuoteEditDialogAreaComponent } from './quote-edit-dialog-area/quote-edit-dialog-area.component';
import { QuoteEditDialogDietComponent } from './quote-edit-dialog-diet/quote-edit-dialog-diet.component';
import { QuoteEditDialogWorkcenterComponent } from './quote-edit-dialog-workcenter/quote-edit-dialog-workcenter.component';
import { QuoteEditEquipmentDialogComponent } from './quote-edit-equipment-dialog/quote-edit-equipment-dialog.component';
import { QuoteEditGasComponent } from './quote-edit-gas/quote-edit-gas.component';
import { QuoteEditOutsourcesComponent } from './quote-edit-outsources/quote-edit-outsources.component';
import { QuoteEditRoomDialogComponent } from './quote-edit-room-dialog/quote-edit-room-dialog.component';
import { QuoteEssayAirComponent } from './quote-essay-air/quote-essay-air.component';
import { QuoteStatusPipe } from './quote-edit-pipe/quote-status.pipe';
import { QuoteEditDialogStandarComponent } from './quote-edit-dialog-standar/quote-edit-dialog-standar.component';
import { QuoteEditDialogProfileComponent } from './quote-edit-dialog-profile/quote-edit-dialog-profile.component';
import { QuoteConsultoryQualificationsComponent } from './quote-consultory-qualifications/quote-consultory-qualifications.component';
import { TrainingEditDialogComponent } from './quote-edit-dialog-training/quote-edit-dialog-training.component';
import { AuditEditDialogComponent } from './quote-edit-dialog-audit/quote-edit-dialog-audit.component';
import { QuoteDialogEquipmentThermal } from './quote-dialog-equipment-thermal/quote-dialog-equipment-thermal';
import { QuoteDialogTestThermal } from './quote-dialog-test-thermal/quote-dialog-test-thermal';
import { QuoteDialogProbeThermal } from './quote-dialog-probe-thermal/quote-dialog-probe-thermal';
import { CalibrationEditDialogComponent } from './quote-edit-calibration-dialog/quote-edit-calibration-dialog';
import { MasiveCalibrationDialogComponent } from './quote-edit-dialog-masive-calibration/quote-edit-dialog-masive-calibration.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationHoursDialogComponent } from './quote-edit-confirmationHours-dialog/quote-edit-confirmationHours-dialog';


@NgModule({
  declarations: [
    QuoteEditComponent, QuoteEssayAirComponent, QuoteEditRoomDialogComponent, QuoteEditOutsourcesComponent,
    QuoteEditEquipmentDialogComponent, QuoteEditGasComponent, QuoteEditComponent, QuoteEditDialogWorkcenterComponent,
    QuoteEditDialogAreaComponent, QuoteEditDialogDietComponent, QuoteTypeAirPipe, QuoteStatusPipe, QuoteEditDialogStandarComponent, QuoteEditDialogProfileComponent, QuoteConsultoryQualificationsComponent, QuoteStatusPipe, TrainingEditDialogComponent,

    AuditEditDialogComponent, QuoteDialogEquipmentThermal, QuoteDialogTestThermal,  QuoteDialogProbeThermal, CalibrationEditDialogComponent, MasiveCalibrationDialogComponent ,ConfirmationDialogComponent,

    AuditEditDialogComponent, QuoteDialogEquipmentThermal, QuoteDialogTestThermal,  QuoteDialogProbeThermal, CalibrationEditDialogComponent, MasiveCalibrationDialogComponent ,  ConfirmationHoursDialogComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    /* Custom modules */
    AngularMaterialModule,
    /* Otros */
    FlexLayoutModule,
    RouterModule,
    SharedModule,
    PipesModule,
    QuoteDetailModule,
   
  ],
  exports: [

    QuoteEditComponent

  ],
})
export class QuoteEditModule { }

