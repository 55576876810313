<h1 mat-dialog-title>{{ 'quote.calibration.title' | translate }}</h1>
<form fxLayout="column">
    <div mat-dialog-content class="quotit-theme" fxLayout="column">
         <!-- Type select -->
         <div fxFlex="100">
            <div fxFlex="12" fxFlex.lt-md="100" (click)="uploadEquipmentCalibration.click()">
                <button mat-raised-button color="primary">{{ 'button.upload' | translate  }}</button>
            </div>

            <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadFile($event)"
                #uploadEquipmentCalibration>
            <div fxFlex="12" fxFlex.lt-md="100">
                <button mat-raised-button (click)="downloadFile()" color="primary">{{ 'button.download-template' | translate  }}</button>
            </div>
         </div>

         <div fxFlex="100">
            <table #protocols mat-table matSort [dataSource]="calibrations">

                <!-- COLUMN  -->
                <ng-container id="equipmentType" matColumnDef="equipment">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.equipment' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'equipmentType.'+getNameEquipment(item.equipmentType) | translate }} </td>
                </ng-container>

                <!-- COLUMN  -->
                <ng-container id="maker" matColumnDef="maker">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.maker' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.maker}} </td>
                </ng-container>
                
                <!-- COLUMN  -->
                <ng-container id="model" matColumnDef="model">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.model' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.model}} </td>
                </ng-container>
        
                 <!-- COLUMN  -->
                 <ng-container id="nserie" matColumnDef="nserie">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.nserie' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.serie}} </td>
                </ng-container>

                <!-- COLUMN  -->
                <ng-container id="indicative" matColumnDef="indicative">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.indicative' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.indicative}} </td>
                </ng-container>

                <!-- COLUMN  -->
                <ng-container id="location" matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.location' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{item.location}} </td>
                </ng-container>

                <!-- COLUMN  -->
                <ng-container id="variableType" matColumnDef="variable">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.variable' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'variableType.'+getNameVariable(item.variableType) | translate }} </td>
                </ng-container>
        
                <!-- COLUMN  -->
                <ng-container id="unitType" matColumnDef="unitType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.unit' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'unitType.'+getNameUnit(item.unitType) | translate }} </td>
                </ng-container>
        
                <!-- COLUMN  -->
                <ng-container id="calibrationSite" matColumnDef="calibrationSite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.site' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'calibrationSite.' + getNameSite(item.calibrationSite) | translate }} </td>
                </ng-container>
        
                <!-- COLUMN  -->
                <ng-container id="calibrateRange" matColumnDef="calibrateRange">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibration.dialog.uloadp.cols.range' | translate }} </th>
                  <td mat-cell *matCellDef="let item"> {{ 'calibrateRange.'+getNameRange(item.calibrationRange) | translate }} </td>
                </ng-container>
               

                 

                <!-- FIN BOTONERA DE ACCIONES LISTA PAGINADA-->
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
              </table>
         </div>
    </div>

     <!-- Botonera -->
     <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
            }}
        </button>
    </div>
</form>

