import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { Test, TestType } from 'src/app/model/test';
import { TestTypeEquipmentConfig } from 'src/app/model/testTypeEquipmentConfig';
import { TestTypeService } from 'src/app/service/testType.service';

@Component({
  selector: 'app-quote-essay-air',
  templateUrl: './quote-essay-air.component.html'
})
export class QuoteEssayAirComponent implements OnInit {

  @Input() testsConfig: TestTypeEquipmentConfig[];
  @Input() testSaved: Test[];

  @Output() testCheckEmitter = new EventEmitter<any>();

  testTypes: TestType[] = [];
  
  constructor(
    private testTypeService: TestTypeService
  ) {
  }

  ngOnInit(): void {
    this.testTypeService.findAll().subscribe((res: TestType[]) => {
      this.testTypes = res;
    });
  }

  onCheckTest(event: MatCheckboxChange, test: TestTypeEquipmentConfig): void {
    this.testCheckEmitter.emit({
      idTestType: test.idTestType,
      checked: event.checked,
      id:test.id,
      time:test.time
    });
  }

  isChecked(config: TestTypeEquipmentConfig): boolean {
    this.testSaved?.forEach(saved => {
      if (saved.idType === config.idTestType) {
        return true;
      }
    });

    return false;
  }

  showTestName(idType: number):string {
    let name = '';
    name = this.testTypes.find(eq => eq.id == idType).translate;

    return 'testType.'.concat(name);
  }

}
