<div class="main-container">
    <form #protocolListForm="ngForm">
      <div class="toolbar-list">
  
        <div class="toolbar-list-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1 class="accent-color">{{ 'courseList.title' | translate }}</h1>
          </div>
  
          <div class="breadcrumbs">
            <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'courseList.title' | translate }}</span>
          </div>
        </div>
  
        <div class="toolbar-list-actions">
          <button mat-raised-button (click)="new()" color="accent"
            matTooltip="{{ 'button.disabled.offline' | translate }}">
            <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
          </button>
        </div>
  
        <div class="toolbar-back-button">
          <a (click)="cancel()" mat-button color="accent">
            <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
          </a>
        </div>
      </div>
  
      <app-user-list-filter (filterEmitter)="doFilter($event)" class="filter-container" >
      </app-user-list-filter>

      <div class="table-container">
        <table #protocols mat-table matSort [dataSource]="dataSource">
  
          <!-- name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.name' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.fullName }} </td>
          </ng-container>

          <!-- name Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'courseList.cols.role' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.roleTranslation }} </td>
          </ng-container>
  
          <!-- Button download Column -->
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'courseList.cols.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton"
                  matTooltip="{{ 'button.download' | translate }}">
                  <mat-icon>cloud_download</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
  
          <!-- Button edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <!-- Button delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton"
                  matTooltip="{{ 'button.delete' | translate }}">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
  
          <!-- Button more Column -->
          <ng-container matColumnDef="  " stickyEnd>
            <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
            <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">
  
              <mat-menu #appMenu="matMenu" class="moreActions">
                <ng-template matMenuContent let-name="element">
                  <button mat-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton">
                    <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                  </button>
                  <button mat-button (click)="editRow(element.id)">
                    <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                  </button>
                  <button mat-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton">
                    <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                  </button>
                </ng-template>
              </mat-menu>
  
              <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
                <mat-icon>more_horiz</mat-icon>
              </button>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
        <span *ngIf="!hasFiltered" class="prefilterMessage">
          <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
        </span>
      </div>
  
      <div class="table-footer-container">
        <div class="actions">
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color">
            {{ 'button.backToTop' | translate }} </a>
        </div>
        <div class="paginator">
          <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            [length]="dataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </form>
  </div>