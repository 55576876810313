/* eslint-disable @typescript-eslint/member-ordering */

import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { DrawerService } from 'src/app/service/drawer.service';
import { FooterComponent } from 'src/app/components/shared/footer/footer.component';
import { LoginService } from 'src/app/service/login.service';
import { MatSidenav } from '@angular/material/sidenav';
import { StringUtils } from 'src/app/utils/stringUtils';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { takeUntil } from 'rxjs/operators';
import { ROLE_USERS } from 'src/app/utils/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<number>();

  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild(FooterComponent, { static: true }) footer: FooterComponent;

  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean;

  user = new User();

  private destroy$ = new Subject<void>();

  constructor(
    private route: Router,
    private loginService: LoginService,
    private drawerService: DrawerService,
    private userService: UserService,
    private router: Router,
    private elRef: ElementRef) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.loginService.isLoggedIn$;
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(item => {
      this.isLoggedIn = item;
    });
    this.isLoggedIn = localStorage.getItem('profile') != null;
    this.drawerService.setDrawer(this.sidenav);

    this.route.events.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val instanceof NavigationStart) {
        if (!val.url.startsWith('/login') && !val.url.startsWith('/signup')) {
          this.updateSidebar();
        }

        const sidenavContent = this.elRef.nativeElement.querySelectorAll('mat-sidenav-content')[0];

        sidenavContent.scrollTo(0, 0);
      }
    });
  }

  public get roleUsers(): typeof ROLE_USERS {
    return ROLE_USERS; 
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSidebar(): void {
    if (this.isLoggedIn) {
      this.userService.profile().pipe(takeUntil(this.destroy$)).subscribe((item: User) => {
        if (item.username == null) {
          this.loginService.logout();
        }

        this.user = item;

        this.filterEmitter.next(item.unreadNotifications);
      });
    }
  }

  getInitials(): string {
    let res = '';
    if (this.user.fullName) {
      res = StringUtils.getInitials(this.user.fullName);
    }
    return res;
  }

  goToPage(page: string, field: string, id: number): void {
    let url = '/' + page;

    if (id != null) {
      url = `${url}?${field}=${id}`;
    }

    void this.router.navigateByUrl(url);
  }
}
