<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
    <div mat-dialog-content class="quotit-theme">
        <div fxLayout="row" class="flexwrap">
            <div fxFlex="100%">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'quoteEdit.dialog.equipment.type.label' | translate }}</mat-label>
                    <mat-select required name="eqIdType" [(ngModel)]="data.equipment.idType"
                        (selectionChange)="onTypeChange($event)">
                        <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
                            {{ item.translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.type.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="flexwrap">
            <!-- Number -->
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showNumber()">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.equipment.inventoryNumber" name="eqnumber" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.number.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.number.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Model -->
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showModel()">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.equipment.model" name="eqmodel" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.model.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.model.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Serial number -->
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showSerialNumber()">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.equipment.serialNumber" name="eqserialNumber" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.serialNumber.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.maker.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Location -->
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showlocation()">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.equipment.location" name="eqlocation" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.location.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.location.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Count Rest -->
            <div fxFlex="15" fxFlex.lt-md="100%" *ngIf="showCountRest()">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'quoteEdit.dialog.equipment.count.label' | translate }}</mat-label>
                    <mat-select name="equnit" required (selectionChange)="onCountCriteriaChangeRest($event)"
                        [(ngModel)]="data.equipment.idCountEssayCriteria">
                        <mat-option value="null">--</mat-option>
                        <mat-option *ngFor="let countEssayCriteria of countEssayCriteriasRest"
                            [value]="countEssayCriteria.id">
                            {{countEssayCriteria.reference}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.count.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Count Working-->
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showCountWorking()">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'quoteEdit.dialog.equipment.countWorking.label' | translate }}</mat-label>
                    <mat-select name="equnitWorking" required (selectionChange)="onCountCriteriaChangeWorking($event)"
                        [(ngModel)]="data.equipment.idCountEssayCriteriaWorking">
                        <mat-option value="null">--</mat-option>
                        <mat-option *ngFor="let countEssayCriteria of countEssayCriteriasWorking"
                            [value]="countEssayCriteria.id">
                            {{countEssayCriteria.reference}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.count.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- CountPoints -->
            <div fxFlex="12" fxFlex.lt-md="100%" *ngIf="showCountPoints()">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.equipment.countPoints" name="eqcountPoints" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.countPoints.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.countPoints.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Surface -->
            <div fxFlex="12" fxFlex.lt-md="100%" *ngIf="showSurface()">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.equipment.surface" name="eqsurface" required
                        placeholder="{{ 'quoteEdit.dialog.equipment.surface.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.surface.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Filter -->
            <div fxFlex="12" fxFlex.lt-md="100%" *ngIf="showFilter()">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.equipment.totalFilters" name="eqfilter"
                        placeholder="{{ 'quoteEdit.dialog.equipment.totalFilters.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.totalFilters.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Diffuser -->
            <div fxFlex="12" fxFlex.lt-md="100%" *ngIf="showDiffuser()">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.equipment.totalDiffusers" name="eqdiffuser"
                        placeholder="{{ 'quoteEdit.dialog.equipment.totalDiffusers.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.totalDiffusers.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Micro -->
            <div fxFlex="10" fxFlex.lt-md="100%" *ngIf="showMicro()">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" required [(ngModel)]="data.equipment.totalMicro" name="eqtotalMicro"
                        placeholder="{{ 'quoteEdit.dialog.equipment.totalMicro.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.equipment.totalMicro.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Outsourced -->
    <mat-toolbar color="secondary">
        {{ 'quoteEdit.dialog.equipment.outsoruced.title' | translate }}
        <div fxFlex="5" fxFlex.lt-md="100%">
            <mat-checkbox style="margin-left: 1em;" (change)="setOutsourced($event.checked)"
                [(ngModel)]="data.equipment.outsourced" name="eqOutsource">
            </mat-checkbox>
        </div>
    </mat-toolbar>
    <div fxLayout="row" class="flexwrap">
        <div fxFlex="90" fxFlex.lt-md="100%" *ngIf="data.equipment.outsourced">
            <app-quote-edit-outsources [providerQuote]="data.equipment.provider"
                (providerQuoteEmitter)="onProviderQuoteEmitter($event)"></app-quote-edit-outsources>
        </div>
    </div>

    <!-- Pantalla ensayos -->
    <mat-toolbar color="secondary" *ngIf="data.equipment.idType != null && data.showAir">
        {{ 'quoteEdit.dialog.equipment.essays.title' | translate }}
    </mat-toolbar>
    <app-quote-essay-air *ngIf="data.showAir" [testsConfig]="testsByEquipment"
    (testCheckEmitter)="onCheckTest($event)"></app-quote-essay-air>
    <mat-toolbar color="secondary" *ngIf="data.equipment.idType != null && data.showQualis">
        {{ 'quoteEdit.dialog.equipment.qualifications.title' | translate }}
    </mat-toolbar>
    <app-quote-consultory-qualifications  [qualificationsConfig]="consultancyTypes"
    (consultoryCheckEmitter)="onCheckQualification($event)" *ngIf="data.equipment.idType != null && data.showQualis">
    </app-quote-consultory-qualifications>
    <div mat-dialog-actions class="quotit-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>