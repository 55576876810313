<h1 mat-dialog-title>{{ tituloDialog }}</h1>
<form fxLayout="column">
    <div mat-dialog-content class="flexwrap">
        <!-- Type select -->
        <div fxFlex="50">
            <mat-form-field class="campoFormulario">
                <mat-label>{{ 'equipmentThermal.title' | translate }}</mat-label>
                <mat-select required [(ngModel)]="data.equipmentThermal.type" name="type">
                    <mat-option *ngFor="let equipment of equipmentTypes" [value]="equipment.id">
                        {{ equipment.translate | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ 'quoteEdit.dialog.equipment.name.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <!-- Equipo -->
        <div fxFlex="50">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.equipment" name="equipment" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.equipment.label' | translate }}">
            </mat-form-field>
        </div>
        <!-- Fabricante -->
        <div fxFlex="50">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.maker" name="maker" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.maker.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Modelo -->
        <div fxFlex="50">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.model" name="model" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.mode.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Nº de serie -->
        <div fxFlex="25">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.identificator" name="identificator" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.identificator.label' | translate }}">
            </mat-form-field>
        </div>

         <!-- Nº de serie -->
         <div fxFlex="25">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.serialNumber" name="serialNumber" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.serialNumber.label' | translate }}">
            </mat-form-field>
        </div>

        <!-- Ubicación -->
        <div fxFlex="50">
            <mat-form-field class="campoFormulario">
                <input matInput [(ngModel)]="data.equipmentThermal.location" name="location" type="text"
                    placeholder="{{ 'quoteEdit.dialog.equipmentThermal.ubication.label' | translate }}">
            </mat-form-field>
        </div>


    </div>
    <!-- Calibración de instrumentación crítica -->
    <div fxFlex="100" class="campoFormulario">
        <label class="checkbox-label" style="margin-right: 10px;">{{ 'quoteEdit.dialog.criticalcalibration' |
            translate }} *
        </label>
        <mat-button-toggle-group name="probes" [(ngModel)]="showProbes" (change)="onCalibrationChange($event)">
            <mat-button-toggle value="1">{{ 'quoteEdit.dialog.yes' | translate }}</mat-button-toggle>
            <mat-button-toggle value="2">{{ 'quoteEdit.dialog.no' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- Botones -->
        <button mat-icon-button color="accent" *ngIf="showProbes === '1'" (click)="openDialogProbeThermal()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <!-- Listado Sondas -->
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-evenly none"
        *ngFor="let thermalProbe of data.equipmentThermal.thermalProbe; let indexProbe = index">
        <div class="campoFormulario">
            <span>{{ 'quoteEdit.dialog.equipmentThermal.type' | translate }}: {{ thermalProbe.type }}</span>&nbsp;
            <span>{{ 'quoteEdit.dialog.equipmentThermal.points' | translate }}: {{ thermalProbe.points
                }}</span>&nbsp;
            <span>{{ 'quoteEdit.dialog.equipmentThermal.values' | translate }}: {{ thermalProbe.values
                }}</span>&nbsp;
        </div>
        <button mat-icon-button color="accent" (click)="editProbeThermal(indexProbe)">
            <mat-icon>mode_edit</mat-icon>
        </button>

        <button mat-icon-button color="accent" (click)="deleteProbeThermal(indexProbe)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <br>
    <br>
    <!-- Añadir Ensayo-->
    <mat-toolbar color="secondary">
        {{ 'quote.testThermal.title' | translate }}
        <button mat-icon-button color="accent" (click)=" openDialogTestThermal()">
            <mat-icon *ngIf="data.equipmentThermal.type != null">add</mat-icon>
        </button>
    </mat-toolbar>

    <!-- LISTADO TESTTHERMAL-->
    <mat-list>
        <mat-list-item *ngFor="let testType of testTypes; let index = index">
            <div>
                {{ testType.translation }}

                <button mat-icon-button color="accent" (click)="editTestThermal(index)">
                    <mat-icon>mode_edit</mat-icon>
                </button>

                <button mat-icon-button color="accent" (click)="deleteTestThermal(index)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </mat-list-item>
    </mat-list>
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start" *ngIf="data.equipmentThermal.type != null">
        <div fxFlex="100" fxLayout="row" *ngFor="let testThermal of data.equipmentThermal.test; let indexTest = index">
            <div class="campoFormulario" fxFlex="auto">
                <span>{{ getModifiedTranslation(testThermal) | translate:{
                    type: showTestName(testThermal.type) | translate,
                    amount: testThermal.amount,
                    setPoint: testThermal.setPoint,
                    charge: testThermal.charge,
                    duration: getTestDuration(testThermal),
                    robeId: testThermal.robeId || ' ',
                    criteria: testThermal.criteria
                    } }}
                </span>
                    
            </div>
            <!-- EDITAR LISTA DE PRUEBAS -->
            <div fxLayout="row" fxLayoutAlign="center">
                <button mat-icon-button color="accent" *ngIf="data.equipmentThermal"
                    (click)="editTestThermal(indexTest)">
                    <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                </button>
                <!-- ELIMINAR LISTA DE PRUEBAS -->
                <button mat-icon-button color="accent" (click)="deleteTestThermal(indexTest)">
                    <mat-icon style="font-size: 20px;">delete</mat-icon>
                </button>

            </div>
        </div>
        <mat-toolbar color="secondary" *ngIf="data.showQualis">
            {{ 'quoteEdit.dialog.equipment.qualifications.title' | translate }}
        </mat-toolbar>
        <app-quote-consultory-qualifications  [qualificationsConfig]="consultancyTypes"
        (consultoryCheckEmitter)="onCheckQualification($event)" *ngIf="data.showQualis">
        </app-quote-consultory-qualifications>
        <!-- Botonera -->
        <div mat-dialog-actions class="thermal-theme" fxLayout="row" fxLayoutAlign="end center">
            <div fxFlex></div>
            <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
            <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
        </div>
    </div>
</form>