<!-------- ---   - ---  ---- -->
<div class="main-container small-layout">
    <form>
  
      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'courseEdit.general.title' | translate }}
          </mat-toolbar>
  
          <div fxLayout="row" class="flexwrap">
  
            <div fxFlex="50" fxFlex.lt-md="100%">
              <div fxLayout="column">
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput [(ngModel)]="course.name" name="name" required
                        placeholder="{{ 'courseEdit.general.name.label' | translate }}">
                      <mat-error>
                        {{ 'courseEdit.general.name.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="card">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" (click)="save()">{{
            'button.save' | translate }}</button>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel'
            | translate }}</button>
  
        </mat-card-actions>
      </mat-card>
  
    </form>
  
  </div>