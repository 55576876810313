import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnitVariableService {

  baseUrl = environment.quotit + '/unitVariable';
  constructor(private http: HttpClient) { }


    findAll(): Observable<any> {
        const url = this.baseUrl + '/';

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    };

    findVariableId(id: number): Observable<any> {
        const url = this.baseUrl + `/variable/${id}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }


    findUnitId(id: number): Observable<any> {
        const url = this.baseUrl + `/unit/${id}`;

        return this.http.get<any>(url).pipe(
            map(httpRes => httpRes)
        );
    }
}

