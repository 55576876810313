import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { ClientFilter } from 'src/app/model/client';

@Component({
  selector: 'app-client-list-filter',
  templateUrl: './client-list-filter.component.html'
})
export class ClientListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ClientFilter>();

  filter: ClientFilter;
  form: FormGroup;
  isList: boolean;
  expandedFilter = true;

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder
  ) {
    this.cleanFilter();

    this.form = fb.group({
      name: [this.filter.name],
      address: [this.filter.address],
      postalCode: [this.filter.postalCode],
      city: [this.filter.city],
      state: [this.filter.state],
      country: [this.filter.country],
      nameBusinessGroup: [this.filter.nameBusinessGroup]
    });
  }

  ngOnInit(): void {
  }

  cleanFilter(): void {
    this.filter = new ClientFilter();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

}
