<!-------- ---   - ---  ---- -->
<div class="main-container small-layout">
  <form>

    <mat-card class="card">
      <mat-card-content>
        <mat-toolbar color="secondary">
          {{ 'userEdit.general.title' | translate }}
        </mat-toolbar>

        <div fxLayout="row" class="flexwrap">

          <div fxFlex="50" fxFlex.lt-md="100%">
            <div fxLayout="column">
              <div fxLayout="row" class="flexwrap">
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="user.username" name="username" required
                      placeholder="{{ 'userEdit.general.username.label' | translate }}">
                    <mat-error>
                      {{ 'userEdit.general.username.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="user.fullName" name="fullName" required
                      placeholder="{{ 'userEdit.general.fullName.label' | translate }}">
                    <mat-error>
                      {{ 'userEdit.general.fullName.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="user.email" name="email" required
                      placeholder="{{ 'userEdit.general.email.label' | translate }}">
                    <mat-error>
                      {{ 'userEdit.general.email.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="user.location" name="location" required
                      placeholder="{{ 'userEdit.general.location.label' | translate }}">
                    <mat-error>
                      {{ 'userEdit.general.location.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'userEdit.general.role.label' | translate }}</mat-label>
                    <mat-select required [(ngModel)]="user.roleId" name="roleId">
                      <mat-option *ngFor="let role of roles" [value]="role">
                        {{ role}}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ 'userEdit.general.role.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'userEdit.general.office.label' | translate }}</mat-label>
                    <mat-select required [(ngModel)]="user.officeId" name="officeId">
                      <mat-option *ngFor="let office of offices" [value]="office">
                        {{ office}}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ 'userEdit.general.office.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>
        </div>


      </mat-card-content>
    </mat-card>

    <mat-card class="card">
      <mat-card-actions>
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="saveUser()">{{
          'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel'
          | translate }}</button>

      </mat-card-actions>
    </mat-card>

  </form>

</div>