import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { TestType } from '../model/test';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TestTypeService {

    currentLang: string;

    private courseSubject = new BehaviorSubject<TestType>(new TestType());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public course$ = this.courseSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.quotit + '/testType';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllThermal(): Observable<any> {
        const url = environment.quotit + '/testTypeThermal/';
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    

}
