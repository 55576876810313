import * as _ from 'lodash-es';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditTypeService } from '../../../service/audit-type.service';
import { AuditSiteService } from '../../../service/audit-site.service';
import { AuditConfigService } from '../../../service/audit-config.service';
import { AuditType } from '../../../model/auditType';
import { AuditSite } from '../../../model/auditSite';
import { Audit } from 'src/app/model/audit';
import { AuditConfig } from 'src/app/model/auditConfig';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '../../../service/country.service';
import { Country } from '../../../model/country';
import { LoadingService } from '../../../service/loading.service';
import { SnackBarService } from '../../../service/snackBar.service';
import { MatSelectChange } from '@angular/material/select';
import { Constants } from 'src/app/utils/constants';
import { ConsultancyProfile } from '../../../model/consultancyProfile';
import { ProfileConsultancyTypeService } from 'src/app/service/profileConsultancyType.service';
import { ProfileConsultancyType } from 'src/app/model/profileConsultancyType';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { AuditProduct } from 'src/app/model/auditProduct';
import { AuditProductService } from 'src/app/service/audit-product.service';

export interface DialogDataAuditConfig {
  audit: Audit;
  isEdit: boolean;
}
@Component({
  selector: 'app-quote-edit-dialog-audit',
  templateUrl: './quote-edit-dialog-audit.component.html'
})
export class AuditEditDialogComponent implements OnInit {

  auditTypes: AuditType[];
  auditSites: AuditSite[];
  countrys: Country[];
  auditProducts: any[] = [];
  // auditProducts: AuditProduct[] = [];
  auditConfig: AuditConfig = new AuditConfig();
  profile: ConsultancyProfile = new ConsultancyProfile();
  profileConsultancyType: ProfileConsultancyType[] = [];

  loading$ = this.loader.loading$;

  audit: Audit = new Audit();
  idTypeSelected: number;
  idSiteSelected: number;
  idProductSelected: number;
  idCountrySelected: number;
  tituloDialog: string;
  showClosedPrice: boolean;
  auditProductId: number[] = [1, 2, 3, 4];
  products: AuditProduct[]=[];
  selectedProducts: { id: number, name: string, supplier: string }[] = [];

  constructor(private auditTypeService: AuditTypeService,
              private auditSiteService: AuditSiteService,
              private auditConfigService: AuditConfigService,
              private translate: TranslateService,
              private countryService: CountryService,
              private auditProductService: AuditProductService,
              private dialogRef: MatDialogRef<AuditEditDialogComponent>,
              private profileConsultancyTypeService: ProfileConsultancyTypeService,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAuditConfig,
              public loader: LoadingService,
              public snackBarService: SnackBarService) { 
    if (this.data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.audit.title.edit') as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.audit.title.create') as string;
    }
  }

  ngOnInit(): void {
    this.audit = this.data.audit;
    this.products = [];
    this.auditTypeService.findAll().subscribe((res: AuditType[]) => {
      this.auditTypes = res;
    });

    this.auditSiteService.findAll().subscribe((res: AuditSite[]) => {
      this.auditSites = res;
    });

    this.countryService.findAll().subscribe((res: Country[]) => {
      this.countrys = res;
    });

    this.auditProducts.push({id: 1}, {id: 2}, {id: 3}, {id: 4});

    this.profileConsultancyTypeService.findAll().subscribe((res: ProfileConsultancyType[]) => {
      this.profileConsultancyType = res;
    });

  }

 
  addProducts() {
    const selectedProductId = this.data.audit.idProduct;
    const product = this.auditProducts.find(p => p.id === selectedProductId);
  
    if (product) {
      this.products = []; 
  
      for (let i = 0; i < selectedProductId; i++) {
        const productData: AuditProduct = {
          idTable: 0, 
          idAudit: this.data.audit.id, 
          idProduct: product.id, 
          name: '', 
          supplier: '' 
        };
  
        this.products.push(productData); 
      }
    }
  }
  findAuditConfig() {
    this.loader.show();
    this.audit.hours = null;
    this.audit.price = null;
    this.audit.journey = null;
    if (this.data.audit.idAuditSite === 1) {
      this.auditConfigService.findConfigFilter(this.audit.auditType, this.audit.idProduct, this.audit.idAuditSite, null).subscribe((res: AuditConfig) => {
        if (res) {
          this.audit.hours = res.hours;
          this.audit.price = res.price;
          this.audit.journey = res.journey;
          this.loader.hide();
          this.addProducts();
        }
      });
    } else if (this.data.audit.idCountry != null) {
      this.auditConfigService.findConfigFilter(this.audit.auditType, this.audit.idProduct, this.audit.idAuditSite, this.audit.idCountry).subscribe((res: AuditConfig) => {
        if (res) {
          this.audit.hours = res.hours;
          this.audit.price = res.price;
          this.audit.journey = res.journey;
          this.audit.idCountry = res.idCountry;
          this.loader.hide();
        }
      });
    } else {
      this.loader.hide();
    }
  }


  onOkClick(): void {
    if (this.validate()) {
      this.data.audit = this.audit;
      this.addProducts(); 
      this.dialogRef.close(this.data.audit);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showJourney(journey: number): string {
    return (journey > 1 ? journey + ' Jornadas' : journey + ' Jornada');
  }

  onChangeType(idType: number) {
    if (idType === this.data.audit.auditType) {
      this.audit = this.data.audit;
    } else {
      this.audit = new Audit();
      this.selectedProducts = [];
    }
    if (this.audit.auditType === 1) {
      this.addProducts(); // Llamar a addProducts() cuando el tipo de auditoría sea igual a 1
    }
  }

  validate(): boolean {
    if (!this.audit.auditType) {
      this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.type.error') as string);
      return false;
    } else {
      if (this.audit.auditType === 1) {
        if (!this.audit.idAuditSite) {
          this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.site.error') as string);
          return false;
        }
  
        if (!this.audit.idProduct) {
          this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.product.error') as string);
          return false;
        }

        if (!this.audit.name) {
          this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.name.error') as string);
          return false;
        }

        if (!this.audit.supplier) {
          this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.supplier.error') as string);
          return false;
        }
  
        if (this.audit.idAuditSite === 2) {
          if (!this.audit.idCountry) {
            this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.country.error') as string);
            return false;
          }
        }
      } else {
        if (!this.audit.profiles || this.audit.profiles?.length === 0) {
          this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.profile.error') as string);
          return false;
        }
      }
    }
    return true;
  }

  onConsultorytypeChance(event: MatSelectChange): void {
    let type: number = event.value;
    this.showClosedPrice = (type === Constants.PROFILE_TYPE_CLOSED_PRICE);
  }
  
  addConsultancyProfile() {
    let ws = true;
    if (!this.audit.profiles) {
      this.audit.profiles = [];
    }

    if (!this.profile.idProfile) {
      ws = false;
      this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.idprofile.error') as string);
    } else if (!this.profile.hours) {
      ws = false;
      this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.hours.error') as string);
    } else if (!this.profile.price) {
      if (!this.showClosedPrice) {
        if (!this.profile.hours || this.profile.hours > 0) {
          const profileSelect = this.profileConsultancyType.find(a => a.id === this.profile.idProfile);
          this.profile.price = NumberUtils.round(profileSelect.hourPrice * this.profile.hours);
        }
      } else {
        ws = false;
        this.snackBarService.sendWarn(this.translate.instant('quoteEdit.dialog.audit.price.error') as string);
      }
    }

    if (ws) {
      this.audit.profiles.push(_.cloneDeep(this.profile));
      this.profile = new ConsultancyProfile();
    }
  }

  showProfileName(idType: number): string {
    let name = '';
    this.profileConsultancyType.forEach(element => {
      if (element.id === idType) {
        name = element.translate;
      }
    });
    return 'profile.consultancy.type.'.concat(name);
  }

  deleteConsultancyProfile(idProfile) {
    this.audit.profiles.splice(idProfile, 1);
  }

}
