import { Client, ClientFilter } from '../model/client';
import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.quotit + '/client/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    filter(filter: ClientFilter): Observable<any> {
        const url = environment.quotit + '/client/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.quotit + `/client/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(client: Client): Observable<any> {
        let url = environment.quotit + '/client/';

        if (client.id != null) {
            url += client.id;

            return this.http.put(url, client).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, client).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    activate(id: number, activate: boolean): Observable<any> {
        return this.findOne(id).pipe(map(item => {
            const client = item as Client;
            client.active = activate;
            return this.save(client);
        }, err => {
            return of(err);
        }));
    }
}
